/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import FaqExpandedSection from '@sw/components/Accordions/FaqExpandedSection';
//import FaqSectionV2 from '@sw/components/Accordions/FaqSectionV2';
import LandingPageTopBanner from '@sw/components/Banners/LandingPageTopBanner';
import SimpleButton from '@sw/components/Buttons/SimpleButton';
import Quote from '@sw/components/Cards/Quote';
import QuoteImageSignature from '@sw/components/Cards/QuoteImageSignature';
import ZigZagItenary from '@sw/components/Cards/ZigZagItenary';
import SectionTitle from '@sw/components/Headings/SectionTitle';
import SingleImage from '@sw/components/Img/SingleImage';
import MidContainer from '@sw/components/Layout/MidContainer';
import Gallery from '@sw/components/Sliders/Gallery';
import LinkClickGallery from '@sw/components/Sliders/LinkClickGallery';
import DatesAndRegistrations from '@sw/components/Tables/DatesAndRegistrationTable';
import IshaMaintainsText from '@sw/components/Text/IshaMaintainsText';
import SingleLineText from '@sw/components/Text/SingleLineText';
import StructuredTextParser from '@sw/components/Utility/StructuredTextParser';
import Convenience from '@sw/sections/Convenience';

/* Services */
/* Styles */

/**
 * Renders the CommonComponents Component
 *
 * @param {object} sectionContent - Content from API
 * @param {string} language - Language of page
 * @param {string} region - Region of Page
 * @param {boolean} isLeftMenu - Left Navbar
 * @param {string} pType
 * @param {string} pageName
 * @returns {ReactElement} UpcomingDates component
 */

const CommonComponents = ({
  sectionContent,
  region,
  language,
  section,
  isLeftMenu,
  pType,
  pageName,
  csrData,
  pageData,
  index,
}) => {
  const router = useRouter();
  return (
    <Box w="100%" display="flex" flexDirection="column" pb="0">
      {RenderComponents({
        sectionContent,
        region,
        language,
        section,
        isLeftMenu,
        pType,
        pageName,
        csrData,
        router,
        pageData,
        index,
      })}
    </Box>
  );
};

const RenderComponents = ({
  sectionContent,
  region,
  language,
  section,
  pType,
  pageName,
  csrData,
  router,
  pageData,
  index,
}) => {
  switch (sectionContent._modelApiKey) {
    case 'table_editor':
      return <DatesAndRegistrations sectionContent={sectionContent} />;
    case 'landing_page_top_banner':
      return (
        <LandingPageTopBanner
          sectionContent={sectionContent}
          region={region}
          language={language}
        />
      );
    case 'structured_text':
      return (
        <MidContainer w="1170px">
          <Box
            className="text"
            px={{ base: '15px', md: '16px' }}
          //pb={{ base: '40px', md: '40px' }}
          >
            <StructuredTextParser
              str={render(sectionContent.body)}
              region={region}
              sectionContent={sectionContent}
              lang={language}
              textAlign={sectionContent.style}
              maxW="none"
              fontFamily='FedraSansStd-book'
              lineHeight="28px"
            />
          </Box>
        </MidContainer>
      );
    case 'single_line_text':
      if (sectionContent?.fieldId === 'isha-maintains') {
        return <IshaMaintainsText sectionContent={sectionContent} />;
      }
      return (
        <Box>
          <SingleLineText sectionContent={sectionContent} />
        </Box>
      );
    case 'single_image':
      return (
        <SingleImage
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'section_title':
      return (
        <SectionTitle
          titleObj={sectionContent}
          maxW={{ base: '280px', sm: '350px', md: '663px' }}
          mx={{ base: '17px', md: 'auto' }}
        />
      );
    case 'simple_button':
      return (
        <SimpleButton
          style={sectionContent}
          region={region}
          lang={language}
          mx="auto"
        />
      );

    case 'faq_expanded_section':
      return (
        <FaqExpandedSection
          faqSection={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'gallery':
      if (sectionContent.fieldId === 'link-click') {
        return (
          <Box>
            <LinkClickGallery sectionContent={sectionContent} />
          </Box>
        );
      }
      return (
        <Box>
          <Gallery sectionContent={sectionContent} />
        </Box>
      );
    case 'quote':
      if (sectionContent.decoration === 'quote-symbol-image-signature') {
        return (
          <>
            <QuoteImageSignature sectionContent={sectionContent} />
          </>
        );
      }
      return (
        <>
          <Quote sectionContent={sectionContent} />
        </>
      );
    case 'generic_iframe':
      const GenericFrame = dynamic(() =>
        import('@components/Embeds/GenericFrame')
      );
      return <GenericFrame sectionContent={sectionContent} section={section} />;
      {/*  case 'faq_section_v2':
      return (
        <MidContainer w="770px">
          <FaqSectionV2
            faqSection={sectionContent}
            region={region}
            lang={language}
          />
        </MidContainer>
      ); */}
    case 'card_zigzag_group':
      if (sectionContent.style === 'sw-timeline') {
        return (
          <ZigZagItenary
            sectionContent={sectionContent}
            region={region}
            lang={language}
          />
        );
      } else {
        return (
          <>
            <Convenience
              sectionContent={sectionContent}
              region={region}
              lang={language}
            />
          </>
        );
      }
  }
};

export default CommonComponents;
