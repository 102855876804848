/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { StructuredText } from 'react-datocms';
import { BsFillCaretDownFill } from 'react-icons/bs';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const SwProgramGuide = ({ sectionContent, section }) => {
  const { contentBlock } = sectionContent[0];
  // const [accordionIndex, setAccordionIndex] = useState(0);

  return (
    <Flex justify="center">
      <Box
        maxW="1170px"
        w="100%"
        m={{ base: '50px 0px', md: '90px 40px', xl: '137px 80px' }}
        p={{ base: '40px 20px', md: '50px 90px', xl: '80px 140px' }}
        borderRadius="15px"
        boxShadow="inset 0px 8px 13px 0 rgba(0, 0, 0, 0.28)"
        bg="#f9f9f9"
      >
        <Accordion
          // onChange={indx => {
          //   setAccordionIndex(indx);
          // }}
          allowMultiple={true}
          defaultIndex={[0]}
          allowToggle={true}
        >
          {contentBlock?.map((content, index) => {
            return (
              <AccordionItem
                border="none"
                key={nanoid(5)}
                mb="20px"
                boxShadow="rgba(0, 0, 0, 0.10) 6px 8px 12px 0px, rgba(0, 0, 0, 0.10) 0px 10px 10px"
                borderRadius="15px"
              >
                {({ isExpanded }) => (
                  <>
                    <Box as="h2">
                      <AccordionButton
                        borderRadius="15px"
                        // borderBottomLeftRadius="0"
                        // borderBottomRightRadius="0"
                        p="0"
                        color="#356190"
                        _expanded={{
                          bg: ' #356190',
                          borderRadius: '15px 15px 0px 0px',
                          color: 'white',
                        }}
                      >
                        <Flex
                          h={{ base: '100px', xl: '130px' }}
                          // as="span"
                          flex="1"
                          p={{ base: '1rem 20px', xl: '2rem 44px' }}
                          align="center"
                          gridGap={{ base: "1.2rem", sm: '1.5rem', xl: '45px' }}
                          borderRadius="15px"
                          textAlign="left"
                        >
                          <Box
                            w={{ base: "35px", sm: '45px', xl: '55px' }}
                            h={{ base: "35px", sm: '45px', xl: '55px' }}
                          >
                            <Image
                              // w="60%"
                              h="100%"
                              objectFit="fill"
                              maxW={{ base: "35px", sm: '45px', xl: '55px' }}
                              maxH={{ base: "35px", sm: '45px', xl: '55px' }}
                              alt={content.image?.alt}
                              src={content.image?.url}
                              filter={!isExpanded ? 'invert(41%) sepia(50%) saturate(510%) hue-rotate(180deg) brightness(78%) contrast(87%)' : ''}

                            />
                          </Box>
                          <Box
                            fontFamily="FedraSansStd-book"
                            fontSize={{ base: '20px', md: '30px', xl: '36px' }}

                          >
                            {' '}
                            {content.title}
                          </Box>
                        </Flex>

                        <AccordionIcon
                          as={BsFillCaretDownFill}
                          mr={{ base: '25px', md: '44px' }}
                          width="27px"
                          fontSize="24px"
                          _hover={{ fill: isExpanded ? `#fff !important` : `#356190 !important` }}
                        />
                      </AccordionButton>
                    </Box>

                    <AccordionPanel
                      // boxShadow="0px 11px 18px 0 rgba(0, 0, 0, 0.17) "
                      p={{ base: '30px 50px', xl: '53px 76px 54px 160px' }}
                      borderRadius="15px"
                    >
                      {content?.titleSubtext && (
                        <Box
                          fontSize="25px"
                          fontWeight="bold"
                          mb={{ base: '2rem', md: '46px' }}
                        >
                          {content?.titleSubtext}
                        </Box>
                      )}
                      <Box
                        className="programGuide"
                        mb={{ base: '1.5rem', md: '2rem' }}
                      >
                        <StructuredText
                          fontFamily="FedraSansStd-book"
                          data={content?.description?.value?.document}
                        />
                      </Box>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            );
          })}
        </Accordion>

        <Box textAlign="center" mx="auto" maxW="714px" className="programGuide">
          <StructuredText data={sectionContent[1].body.value.document} />
        </Box>
      </Box>
      <style jsx global>{`
        .programGuide li {
        list-style-type:none;
        }
        .programGuide li{
          position: relative;
        }
        .programGuide li::before {
        content: "";
        background:#356190;
        left:-21px;
        top:8px;
        width:11px;
        position:absolute;
        height:11px;
        border-radius:50%;
        }
        .programGuide ul{
          padding-left: 20px !important; 
        }
        .programGuide p {
          padding-bottom: 20px;
          font-size: 16px;
          line-height: 26px;
          font-family: FedraSansStd-book;
        }
        .programGuide h6  {
          font-style: normal;
          font-size: 20px;
          line-height: 36px;
          font-weight: bold;
          fontFamily="FedraSansStd-A-medium"
          text-transform:"capitalize"

          .collapsed img {
            filter: invert(41%) sepia(50%) saturate(510%) hue-rotate(180deg) brightness(78%) contrast(87%) !important;
        }
          
        }
       
      `}</style>
    </Flex>
  );
};

export default SwProgramGuide;
