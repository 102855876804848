/* Built In Imports */
import Link from 'next/link';

/* External Imports */
import {
  Box,
  Button,
  Flex
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';

export default function DesktopDARTable({ sectionContent }) {
  return (
    <Box
      bgColor="white"
      m="20px auto 50px auto"
      maxW={{ base: '100%' }}
      borderRadius="15px"
      padding={{ base: "0", md: "0 25px" }}
    >
      <Flex
        borderRadius="12px"
        borderBottomLeftRadius="0"
        borderBottomRightRadius="0"
        borderRightRadius="0"
        flexGrow="1"
        bgColor="#34628F"
      >
        {sectionContent?.table?.columns?.map((col, index) => {
          return (
            <Box
              p={{ base: "16px 5px", md: "16px 4px" }}
              key={nanoid()}
              flexGrow="1"
              w={{ base: index ? "auto" : "auto", md: "25%" }}
              fontSize={{ base: "12px", md: "18px", xl: "22px" }}
              style={{
                textWrap: 'wrap',
                textAlign: 'center',
                backgroundColor: '#34628F',
                fontFamily: "FedraSansStd-A-medium",
                color: "#fff",
              }}
            >
              {col}
            </Box>
          );
        })}
      </Flex>

      {sectionContent?.table?.data?.map((datum, index) => {
        return (
          <Flex
            flexGrow="1"
            justifyContent="center"
            key={nanoid()}
            backgroundColor="#F1F1F1"
            mt={index && "20px"}
            p={{ base: "16px 3px", md: "16px 5px" }}
            borderRadius="8px"
            borderTopRadius={!index && 0}
          >
            {
              Object.keys(datum)?.map((column, i) => {
                let mobTdWidth = "32%";
                if (i === 0) {
                  mobTdWidth = "15%";
                } else if (i === 3) {
                  mobTdWidth = "auto";
                }
                return (
                  <Box
                    key={nanoid()}
                    flexGrow={{ base: "initial", md: "1" }}
                    w={{ base: mobTdWidth, md: "25%" }}
                    fontSize={{ base: "12px", md: "18px", xl: "22px" }}
                    borderRight={{ base: "0", sm: i < Object.keys(datum).length - 1 && '1px solid #a4aebd !important' }}
                    style={{
                      textAlign: 'center',
                      color: '#3A3A3A',
                      fontFamily: "FedraSansStd-book",
                    }}
                  >
                    <Box>
                      {(datum[column]?.includes('https://') || datum[column]?.indexOf('/sacred') > -1) ? (
                        <Link
                          href={datum[column]}
                          variant="primary"
                          textDecoration="none"
                          _hover={{ textDecoration: 'none' }}
                          _focus={{ textDecoration: 'none' }}
                          _active={{ textDecoration: 'none' }}
                          target="_blank"
                        >
                          <Button
                            maxW={{ base: "80px", md: "189px" }}
                            h={{ base: "29px", md: "49px" }}
                            borderRadius="6px"
                            fontSize={{ base: "12px", md: "17.5px" }}
                            bg="#35618f"
                            color="white"
                            fontWeight="500"
                            bgColor="#CF4520"
                            _hover={{ bgColor: '#c76356' }}
                            _focus={{ outline: 0 }}
                            p={{ base: "10px", md: "27px" }}
                          >
                            Register
                          </Button>
                        </Link>
                      ) : (
                        <Box
                          pt="10px"
                          color={datum[column]?.toLowerCase() === 'closed' ? "#A40303" : ""}
                          fontFamily={datum[column]?.toLowerCase() === 'closed' ? "FedraSansStd-A-medium" : ""}
                        >
                          {datum[column]}
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })
            }
          </Flex>
        );
      })}
    </Box>
  );
}
