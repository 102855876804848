/* External Imports*/
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import parser from 'html-react-parser';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/**
 *
 * @param {Object} text
 * @returns
 */
const BlueBanner = ({ text, bType }) => {
  const getValue = parser(text?.title);

  return (
    <>
      <Flex
        color="white"
        w="100%"
        bg="#35618f"
        justify="center"
        flexWrap="wrap"
        fontFamily="'FedraSansStd-A-medium'"
        py="16px"
      >
        {getValue !== null ? (
          <>
          {/*   {bType && (
              <Box
                fontSize={{ base: '16px', md: '20px' }}
                textAlign="center"
                width="100%"
              >
                {getValue}
              </Box>
            )}*/}
            <Box>
              <StructuredTextParser
                str={render(text?.bottomText?.value)}
                mt="0px"
                fontSize={{ base: '1.5rem', md: '36px' }}
              />
            </Box>
          </>
        ) : (
          <Box>
            <StructuredTextParser
              str={render(text?.bottomText?.value)}
              mt="0px"
              fontSize={{ base: '1.5rem', md: '54px' }}
            />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default BlueBanner;
