/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex, Image, Show, useBreakpointValue } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { AiFillCaretDown } from 'react-icons/ai';
import { LightBox } from 'react-lightbox-pack';
import { useMediaQuery } from 'react-responsive';
import { Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

/* Internal Imports */
/* Components */
/* Services */
/* Styles */
import 'react-image-lightbox/style.css';
import "react-lightbox-pack/dist/index.css";
import 'swiper/css';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const Gallery = ({ sectionContent }) => {
  const [toggle, setToggle] = useState(false);
  const [sIndex, setSIndex] = useState(0);
  const [images, setImages] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  sectionContent?.images.forEach(element => {
    element.image = element.url;
  });

  // Handler
  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };

  const addImages = () => {
    setImages([
      ...images,
      ...sectionContent.images.slice(images?.length, images?.length + 8),
    ]);
  };

  const openImage = (index) => {
    if (isMobile) {
      setSIndex(index);
      setToggle(true);
    } else {
      lightBoxHandler(true, index)
    }
  };

  useEffect(() => {
    setImages([...sectionContent.images.slice(0, 9)]);
  }, []);

  return (
    <>
      <Flex
        width="100%"
        mx="auto"
        px="16px"
        maxW="1170px"
        my={{ base: '20px', md: '30px' }}
        gridGap="20px"
        flexWrap="wrap"
        className="gallery"
      >
        {images?.map((el, index) => {
          return (
            <Box
              display="flex"
              key={nanoid(4)}
              width={{ base: '100%', md: '45%', lg: '30%' }}
              mx="auto"
              my={{ base: '0px', md: '20px' }}
            >
              <Image
                alt=""
                height="auto"
                bgSize="cover"
                bgPos="center"
                borderRadius="1rem"
                src={el?.url}
                onClick={() => openImage(index)}
                cursor="pointer"
              />
            </Box>
          );
        })}

        <Show above="md">
          <LightBox
            state={toggle}
            event={lightBoxHandler}
            data={sectionContent?.images}
            imageWidth={useBreakpointValue({ base: '100%', md: '60vw' })}
            imageHeight={useBreakpointValue({ base: 'auto', md: '70vh' })}
            thumbnailHeight={useBreakpointValue({ base: 0, md: 100 })}
            thumbnailWidth={useBreakpointValue({ base: 0, md: 100 })}
            setImageIndex={setSIndex}
            imageIndex={sIndex}
          />
        </Show>

        <style jsx global>
          {`
            ._3bkri {
              background-color: #49423b;
            }
            ._3bkri:hover {
              background-color: #49423b;
            }
            ._20cg_:focus,
            ._3bkri:focus {
              box-shadow: 0px 0px 0px 3px #49423b;
            }
            @media (max-width: 767px) {
            .gallery button {
              // display: block;
            }
            ._3ZUEV {
top: 1rem;
    right: 1rem;
}
._2pWTS {
  left:1%
}
._18xMp {
  right: 1%;
}
._3bkri {
    background-color: transparent;
    width: 2rem;
}
._3bkri:hover {
    background-color: transparent;
}
._20cg_:focus, ._3bkri:focus {
    box-shadow: none;
}
            }
          `}
        </style>

        {toggle && (
          <Show below="md">
            <Box
              bottom="10px"
              right="15px"
              zIndex="1001"
              color="#ababab"
              pos="fixed"
              fontFamily="FedraSansStd-book"
              w="100%"
              h="100%"
              top="0"
              left="0"
              bgColor="rgba(0,0,0,.9)"
              onClick={() => setToggle(false)}
            >
            </Box>
            <Box
              zIndex="1002"
              pos="fixed"
              w="100%"
              top="30%"
              left="0"
            >
              <Swiper
                modules={[Virtual]}
                //spaceBetween={50}
                initialSlide={sIndex}
                slidesPerView={1}
                onSlideChange={(e) => { setSIndex(e.activeIndex); }}
                // onSwiper={(swiper) => console.log(swiper)}
              >
                {sectionContent?.images?.map((el, index) => {
                  return (
                    <SwiperSlide
                      key={el}
                      virtualIndex={sIndex}
                    >
                      <Image
                        alt=""
                        // mt="42%"
                        height="auto"
                        bgSize="cover"
                        bgPos="center"
                        borderRadius="0"
                        src={el?.url}
                      />{sIndex}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <Box
                bottom="10px"
                right="15px"
                zIndex="1001"
                color="#ababab"
                pos="fixed"
                fontFamily="FedraSansStd-book"
              >
                {sIndex + 1}/{sectionContent?.images.length}
              </Box>
            </Box>
          </Show>
        )}

      </Flex>

      {images.length !== sectionContent?.images.length && (
        <Box
          width="50px"
          title="Load more"
          cursor="pointer"
          mb="60px"
          mx="auto"
          onClick={addImages}
        >
          <AiFillCaretDown color=" #3c6891" size="55px" />
        </Box>
      )}
    </>
  );
};

export default Gallery;
