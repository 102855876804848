/* Built In Imports */

/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { StructuredText } from 'react-datocms';
import { AiOutlineTwitter } from 'react-icons/ai';
import { BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import SimpleButton from '../Buttons/SimpleButton';

/* Internal Imports */
/* Components */

/**
 *
 * @returns
 */
const Contact = ({ sectionContent, content, region, language }) => {
  // console.log("test",sectionContent)
  const { children } = sectionContent[0].body.value.document;

  return (
    <Box as="section">
      <Flex px="44px" mt="83px" mb="20px" flexDir="column" alignItems="center">
        <Box
          boxShadow="28px 27px 25px -34px rgba(99,99,99,0.75) inset"
          display={{ base: 'flex', lg: 'initial' }}
          flexDir="column"
          alignItems="center"
          maxW="1268px"
          bg="#f5f5f5"
          px={{ base: '44px', lg: '140px' }}
          pb="22px"
          pt={{ base: '33px', lg: '90px' }}
          borderRadius="19px"
        >
          <Flex
            gridGap="42px"
            flexDir={{ base: 'column', lg: 'row' }}
            mb="50px"
            alignItems={{ base: 'center', lg: 'initial' }}
          >
            <Flex
              boxShadow="10px 12px 10px -10px rgba(0,0,0,0.3)"
              transition="all 0.3s linear"
              bg="white"
              fontSize="14px"
              flexDir="column"
              p="35px 28px"
              maxW="285px"
              h="350px"
              borderRadius="17px"
            >
              <Box textAlign="center" pt="8px" pb="20px" px="28px" fontFamily="FedraSansStd-Medium">
                <Text>{children[0].children[0].value}</Text>
                {/* <NextLink href="mailto:usa@sacredwalk.org" passHref legacyBehavior> */}
                <Link
                  href="mailto:usa@sacredwalk.org"
                  _hover={{
                    textDecoration: 'none',
                    color: '#c85333',
                  }}>
                  <Text mt="10px">{children[1].children[0].value}</Text>
                </Link>
                {/* </NextLink> */}

              </Box>

              <Box borderTop="1px solid" textAlign="center" py="28px" px="28px" fontFamily="FedraSansStd-Medium">
                <Text >{children[2].children[0].value}</Text>
                {/* <NextLink href="mailto:canada@sacredwalk.org" passHref legacyBehavior> */}
                <Link
                  href="mailto:canada@sacredwalk.org"
                  _hover={{
                    textDecoration: 'none',
                    color: '#c85333',
                  }}>
                  <Text mt="10px">{children[3].children[0].value}</Text>
                </Link>
                {/* </NextLink> */}

              </Box>

              <Box borderTop="1px solid" textAlign="center" py="28px" px="28px" fontFamily="FedraSansStd-Medium">
                <Text>{children[4].children[0].value}</Text>
                {/* <NextLink href="mailto:lb@sacredwalk.org" passHref legacyBehavior> */}
                <Link
                  href="mailto:lb@sacredwalk.org"
                  _hover={{
                    textDecoration: 'none',
                    color: '#c85333',
                  }}>
                  <Text>{children[5].children[0].value}</Text>
                </Link>
                {/* </NextLink> */}
              </Box>
            </Flex>

            <Flex
              boxShadow="10px 12px 10px -10px rgba(0,0,0,0.3)"
              bg="#35618f"
              color="white"
              fontSize="14px"
              flexDir="column"
              p="24px 32px"
              maxW="315px"
              maxH="410px"
              h='auto'
              top={{ lg: '-25px' }}
              pos="relative"
              //  top={{ base: '0', lg: '-20px' }}
              borderRadius="17px"
            >


              <Box textAlign="center" pb="19px" px="28px" fontFamily="FedraSansStd-Medium">
                <Text
                  w="max-content"
                  pos="relative"
                  left="-1.4rem"
                  fontSize="12px"
                >
                  {children[6].children[0].value}
                </Text>
                <Text py="15px">
                  {children[7].children[0].value}
                </Text>
                {/* <NextLink href="mailto:tn@sacredwalk.org" passHref legacyBehavior> */}
                <Link
                  href="mailto:tn@sacredwalk.org"
                  _hover={{
                    textDecoration: 'none',
                    color: '#c85333',
                  }}>
                  <Text>{children[8].children[0].value}</Text>
                </Link>
                {/* </NextLink> */}

              </Box>

              <Box borderTop="1px solid" textAlign="center" py="17px" px="28px" fontFamily="FedraSansStd-Medium">
                <Text
                  w="max-content"
                  pos="relative"
                  left="-1.4rem"
                  fontSize="12px"
                >
                  {children[9].children[0].value}
                </Text>
                <Text py="15px">
                  {children[10].children[0].value}
                </Text>

                {/* <NextLink href="mailto:hyd@sacredwalk.org" passHref legacyBehavior> */}
                <Link
                  href="mailto:hyd@sacredwalk.org"
                  _hover={{
                    textDecoration: 'none',
                    color: '#c85333',
                  }}>
                  <Text>{children[11].children[0].value}</Text>
                </Link>
                {/* </NextLink> */}

              </Box>

              <Box borderTop="1px solid" textAlign="center" pt="17px" pb='25px' px="28px" fontFamily="FedraSansStd-Medium">
                <Text
                  fontSize="12px"
                >
                  {children[12].children[0].value}
                </Text>
                <Text py="5px">
                  {children[13].children[0].value}
                </Text>
                <Text pb="15px">
                  {children[14].children[0].value}
                </Text>

                {/* <NextLink href="mailto:india@sacredwalk.org" passHref legacyBehavior> */}
                <Link
                  href="mailto:india@sacredwalk.org"
                  _hover={{
                    textDecoration: 'none',
                    color: '#c85333',
                  }}>
                  <Text>{children[15].children[0].value}</Text>
                </Link>
                {/* </NextLink> */}
              </Box>

            </Flex>

            <Flex
              boxShadow="10px 12px 10px -10px rgba(0,0,0,0.3)"
              bg="white"
              fontSize="14px"
              flexDir="column"
              p="35px 28px"
              maxW="285px"
              h="350px"
              borderRadius="17px"
            >
              <Box textAlign="center" pt="8px" pb="20px" px="28px" fontFamily="FedraSansStd-Medium">
                <Text
                //fontWeight="bold"
                >{children[16].children[0].value}</Text>

                {/* <NextLink href="mailto:uk@sacredwalk.org" passHref legacyBehavior> */}
                <Link
                  href="mailto:uk@sacredwalk.org"
                  _hover={{
                    textDecoration: 'none',
                    color: '#c85333',
                  }}>
                  <Text mt="10px">{children[17].children[0].value}</Text>
                </Link>
                {/* </NextLink> */}
              </Box>

              <Box borderTop="1px solid" textAlign="center" py="28px" px="28px" fontFamily="FedraSansStd-Medium">
                <Text
                  width="max-content"
                  pos="relative"
                  left="-2.8rem"
                  // fontWeight="bold" 
                  mt="10px"
                >
                  {children[18].children[0].value}
                </Text>
                {/* <NextLink href="mailto:sg@sacredwalk.org" passHref legacyBehavior> */}
                <Link
                  href="mailto:sg@sacredwalk.org"
                  _hover={{
                    textDecoration: 'none',
                    color: '#c85333',
                  }}>
                  <Text>{children[19].children[0].value}</Text>
                </Link>
                {/* </NextLink> */}

              </Box>

              <Box borderTop="1px solid" textAlign="center" py="28px" px="28px" fontFamily="FedraSansStd-Medium">
                <Text
                // fontWeight="bold"
                >{children[20].children[0].value}</Text>
                {/* <NextLink href="mailto:nepal@sacredwalk.org" passHref legacyBehavior> */}
                <Link
                  href="mailto:nepal@sacredwalk.org"
                  _hover={{
                    textDecoration: 'none',
                    color: '#c85333',
                  }}>
                  <Text>{children[21].children[0].value}</Text>
                </Link>
                {/* </NextLink> */}
              </Box>
            </Flex>
          </Flex>

          <Flex fontSize="14px" flexDir="column" gridGap="19px" align="center">
            <StructuredText data={sectionContent[1].body.value.document} />

            <Flex mt="13px" gridColumnGap="27px" fontSize="22px" outline="none">
              {/* <NextLink href='https://twitter.com/ishaSacredWalks' passHref legacyBehavior> */}
              <Link
                href='https://twitter.com/ishaSacredWalks'
                target="_blank"
                cursor='pointer'>
                <AiOutlineTwitter />
              </Link>
              {/* </NextLink> */}

              {/* <NextLink href='https://www.facebook.com/IshaSacredWalks' passHref legacyBehavior> */}
              <Link
                href='https://www.facebook.com/IshaSacredWalks'
                target="_blank">
                <FaFacebookF />
              </Link>
              {/* </NextLink> */}


              {/* <NextLink href='https://www.instagram.com/ishasacredwalks/' passHref legacyBehavior> */}
              <Link
                href='https://www.instagram.com/ishasacredwalks/'
                target="_blank">
                <BsInstagram />
              </Link>
              {/* </NextLink> */}

            </Flex>
          </Flex>
        </Box>
        <SimpleButton
          pt='40px'
          style={{
            buttonStyle: 'Sacred-Walks-Blue',
            buttonText: sectionContent[5]?.buttonText,
            linkUrl: sectionContent[5]?.linkUrl,
          }}
          region={region}
          lang={language}
          mx="auto" />

        {/* <Button
          maxW="185px"
          h="61px"
          mt="70px"
          borderRadius="14px"
          fontSize="20px"
          bg="#35618f"
          color="white"
          _hover={{bgColor:'#c76356'}}
        >
          Enquire Now
        </Button> */}
      </Flex>
      <style global jsx>
        {`
        svg:hover{
          fill: #c85333 !important;
        }
        *:focus {
          outline: none !important;
      }
      a img{
        border: none !important;
        outline: none !important;
      }
      .link.focus, .link:focus {
        outline: 0 !important;
        box-shadow: none!important;
    }
    a :link:focus, :visited:focus {outline: none !important;}

    a:active { outline: none; }.

    a {
      box-shadow: none;
     }
        `}
      </style>
    </Box>
  );
};

export default Contact;
