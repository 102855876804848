/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Button, Link } from '@chakra-ui/react';
import forEach from 'lodash/forEach';
import { useCookies } from 'react-cookie';

/* Internal Imports */
/* Components */
import config from '@config';
import { refineUrlDomain } from '@sw/components/Utility/utils';

/* Services */

/**
 * Returns the Simple Button.
 *
 * @param style.style
 * @param {object} style - Style for button.
 * @param {string} bType - Button Type.
 * @param {string} region - Current User Country.
 * @param {string} language -
 * @param style.clickHandler
 * @param style.padding
 * @param style.textAlign
 * @param style.width
 * @param style.mb
 * @param style.mt
 * @param style.fontstyle
 * @param style.fontbold
 * @param style.extraDomains
 * @param style.disabled
 * @param style.bType
 * @param style.region
 * @param style.lang
 * @param style.target
 * @param style.minH
 * @param style.mx
 * @returns {ReactElement} SimpleButton component.
 */
export default function SimpleButton({
  style,
  bType,
  region,
  lang,
  clickHandler,
  padding,
  textAlign,
  width,
  mb,
  mt,
  fontstyle,
  mx,
  target,
  disabled = false,
  minH,
  ...props
}) {
  const router = useRouter();
  const [currentUrl, setCurrentUrl] = useState('');
  const cookieParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ];
  const [cookies, setCookie] = useCookies(cookieParams);

  useEffect(() => {
    //  if (!router.isReady) return;
    setCurrentUrl(refineUrlDomain(style?.linkUrl));
    //   setTimeout(function () {
    forEach(cookieParams, function (cookie) {
      if (router.query[cookie]) {
        setCookie(cookie, router.query[cookie], {
          path: '/',
          maxAge: 3600 * 24, // Expires after 1year
          sameSite: true,
          domain: config.COOKIE_DOMAIN,
          secure: true,
        });
      }
    });
    // }, 0);
  }, [router, style?.linkUrl]);

  const RenderButton = ({ style }) => {
    const buttonStyle = {
      'Sacred-Walks-Blue': (
        // <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
        <Link
          mx="auto"
          mb="30px"
          href={disabled ? '#' : currentUrl}
          width={width}
          variant="primary"
          textDecoration="none"
          _hover={{ textDecoration: 'none', border: 'none' }}
          _focus={{ textDecoration: 'none', border: 'none' }}
          _active={{ textDecoration: 'none', border: 'none' }}
          target={
            target ||
              (currentUrl?.indexOf('http') > -1 &&
                currentUrl?.indexOf(config.cdnPath) < 0)
              ? '_blank'
              : ''
          }
          {...props}
        >
          <Button
            fontFamily="FedraSansStd-A-medium"
            maxW="185px"
            // h="61px"
            mt="15px"
            fontWeight="400"
            borderRadius="9px"
            fontSize="18px"
            bg="#35618f"
            color="white"
            py='10px' px='15px'
            _hover={{ bgColor: '#c76356' }}
          >
            {style?.buttonText}
          </Button>
        </Link>
        // </NextLink>
      ),
    };
    return buttonStyle[style?.buttonStyle] || null;
  };

  return (
    <Box
      boxSizing="borderBox"
      display={bType ? 'flex' : 'grid'}
      placeItems={'left'}
      as={'button'}
      color="#faf7f0"
      width={'auto'}
      maxW={{ base: '300px', sm: '450px', md: '500px' }}
      h="auto"
      borderRadius="5px"
      fontSize={'18px'}
      textAlign="center"
      justifyContent={bType ? 'center' : ''}
      cursor="default"
      mb={(style?.buttonStyle === 'LB-Sticky-Mobile' && '0') || mb || '30px'}
      minH={
        (style?.buttonStyle === 'LB-Sticky-Mobile' && '0') || minH || '50px'
      }
      mt={
        (style?.buttonStyle === 'LB-Sticky-Mobile' && '0') || mt ? mt : '12px'
      }
      // backgroundImage="/assets/images/lb-white-texture-bg.png"
      background="transparent"
      mx={mx}
    >
      {!bType && currentUrl ? <RenderButton style={style} /> : null}
    </Box>
  );
}
