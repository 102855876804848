/* Built In Imports */
// import NextLink from 'next/link';
import { useRouter } from 'next/navigation';

/* External Imports */
import { Box, Image, List } from '@chakra-ui/react';
// import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import { refineUrlDomain } from '@sw/components/Utility/utils';

/* Services */
/* Styles */

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const LinkClickGallery = ({ sectionContent }) => {
  const router = useRouter();

  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 4,
    arrows: false,
    lazyLoad: false,
    swipeToSlide: true,
    adaptiveHeight: false,
    // afterChange: index => setCurrIndex(index),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          centerMode: false,
          dots: true,
          appendDots: dots => (
            <SliderDots dots={dots} />
          ),
          // customPaging: i => (
          //   <Paging />
          // ),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
          centerMode: true,
          dots: true,
          appendDots: dots => (
            <SliderDots dots={dots} />
          ),
          // customPaging: i => (
          //   <Paging />
          // ),
        },
      }
    ]
  };

  const SliderDots = ({ dots }) => {
    return (
      <Box padding="20px 0 0 0" className="dota">
        <List
          w="97%"
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
        >
          {dots.slice(0, 3)?.map((dot, ind) => {
            const button = dot.props.children;
            return (
              <Box
                key={ind}
                onClick={e => {
                  button.props.onClick(e);
                }}
                width="17px"
                height="3px"
                mx="9px"
                background="#416387"
              />
            );
          })}
        </List>
      </Box>
    );
  };

  const openLink = (link) => {
    console.log('openLink', link);
    router.push(refineUrlDomain(link, '', ''));
  };

  // const   = ({ dots }) => {
  //   return (
  //     <Box
  //       width="17px"
  //       height="3px"
  //       mx="9px"
  //       background="#1a4c82"
  //     ></Box>
  //   );
  // };

  return (
    <Box
      width="100%"
      mb="78px"
      px={{ base: '0' }}
      maxW={{ base: '100%' }}
    >
      <Slider {...slickSettings}>
        {sectionContent?.images?.map((el, index) => {
          // console.log(el?.customData);
          return (
            <Box key={index}>
              {/* <NextLink
                href={refineUrlDomain(el?.customData?.linkUrl || '/', '', '')}
                passHref
                
// legacyBehavior

                key={nanoid()}
              > */}
              {/* <Link> */}
              <Box
                display="flex"
                justifyContent="center"
              >
                <Image
                  alt={''}
                  borderRadius="10px"
                  // height={{ lg: '278px', md: '330px', base: '100%' }}
                  height={{ md: '100%', base: 'auto' }}
                  width={{ md: '98%', base: '98%' }}
                  objectFit="cover"
                  src={el?.url}
                  onMouseUp={() => openLink(el?.customData?.linkUrl || '/')}
                  cursor="pointer"
                />
              </Box>
              {/* </Link>
              </NextLink> */}
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

export default LinkClickGallery;
