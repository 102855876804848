/* Built In Imports */

/* External Imports */
import { Box, Heading, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@sw/components/Utility/StructuredTextParser';

/* Services */
/* Styles */

/**
 * Renders the Zigzag1Row Card component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} Offerings Card component
 */

const ManasarovarText = ({ sectionContent }) => {
  // console.log('ManasarovarText', sectionContent);
  return (
    <Box mx="auto" maxW="1170px" px={{ base: "16px", md: '0px', lg: '16px' }} >
      <Box display="flex" flexDirection={{ base: 'column', lg: 'row' }} py="20px" gridGap="20px" justifyContent="space-between">
        <Box>
          <Heading textAlign={{ base: "center", md: 'center' }} as="h2" fontSize={{ base: '44px', xl: '54px' }}> {sectionContent[0]?.text}</Heading>
          <Text textAlign={{ base: "center", md: 'center' }} fontSize={{ base: '24px', md: '22px', xl: '28px' }}> {sectionContent[1]?.text}</Text>
          <Heading textAlign={{ base: "center", md: 'center' }} fontSize={{ base: '32px', md: '30px', xl: '38px' }}> {sectionContent[2]?.text}</Heading>
        </Box>
        <Box w="full">
          <StructuredTextParser
            str={render(sectionContent[3]?.body)}
            region={''}
            lang={''}
            mt="0px"
            color="#000"
            fontSize="16px"
            px="0px"
            mx="16px"
            maxW="none"
            lineHeight="28px"
            fontFamily='FedraSansStd-book'
          />
        </Box>

      </Box>
    </Box>
  );
};

export default ManasarovarText;
