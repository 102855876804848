/* External Imports */
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { StructuredText } from 'react-datocms';

const Convenience = ({ sectionContent }) => {

  return (
    <Flex
      align="center"
      mb="50px"
      mt={{ base: '60px', xl: '92px' }}
      justify="center"
    >
      <Flex
        maxW="1170px"
        mx={{ base: '20px', md: '40px', xl: '80px' }}
        borderRadius="15px"
        boxShadow="inset 0px 8px 13px 0 rgba(0, 0, 0, 0.28)"
        bg="#f9f9f9"
        p={{ base: '15px', lg:"50px 100px", xl: '70px 16px 70px 32px' }}
        flexDir="column"
        gridGap={{ base: '50px', xl: '90px' }}
      >
        {sectionContent?.cards?.map((card, index) => {
          if (card?.title) {
            return (
              <Flex
                pr="1rem"
                key={nanoid(4)}
                flexDir={{
                  base: 'column',
                  xl:
                    index === sectionContent?.cards.length - 1
                      ? 'row-reverse'
                      : 'row',
                }}
                gridGap={{ base: '10px', xl: '37px' }}
              >
                <Box minW={{ xl: '640px' }}>
                  <Image
                    borderRadius="1rem"
                    alt={card?.thumbnails[0]?.alt || ''}
                    src={card?.thumbnails[0]?.url}
                    w="100%"
                  ></Image>
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: '26px', md: '1.8rem', xl: '30px' }}
                    mb={{ base: '1rem', xl: '41px' }}
                    mt={{base:"1rem", xl:"0"}}
                    fontWeight="500"
                    as="h3"
                    fontFamily="FedraSansStd-A-medium"
                  >
                    {card?.title}
                  </Text>
                  <Box
                    ml="0.7rem"
                    fontFamily="FedraSansStdBook"
                    className="convenience"
                  >
                    <StructuredText
                      data={card?.description?.value?.document}
                      fontSize={{ md: '16px', xl: '16px' }}
                    />
                  </Box>
                </Box>
              </Flex>
            );
          } else {
            return (
              <Flex justify="center" key={nanoid(4)}>
                <Box
                  maxW="800px"
                  fontFamily="FedraSansStd-book"
                  textAlign="center"
                  fontSize={{ md: '16px', xl: '18px' }}
                >
                  <StructuredText data={card?.description?.value?.document} />
                </Box>
              </Flex>
            );
          }
        })}
      </Flex>
      <style jsx global>
        {`
        .convenience p {
          padding-bottom: 10px;
          font-size: 16px;
          line-height: 26px;
          font-family:FedraSansStd-book
        }
        .convenience ul li {
          margin-left:20px
        }
      `}</style>
    </Flex>
  );
};

export default Convenience;
