/* Built In Imports */

/* External Imports */
import { Box, Flex, Heading, Image } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import StructuredTextParser from '../Utility/StructuredTextParser';

/**
 * 
 * @param {Object} sectionContent 
 * @returns 
 */
const ZigZagItenary = ({ sectionContent }) => {
  // console.log(sectionContent)

  return (
    <Box
      maxW={1330}
      mx="auto"
      my="65px"
      mb="2rem"
      width="100%"
    >
      <Box
        className="content-section"
        w="100%"
        maxW={{ sm: 540, md: 720, lg: 960, xl: 1140 }}
        mx="auto"
        px="0.75rem"
      >
        <Box w="100%">
          <Box className="timelinebox">
            <Box className="timeline">
              <Box w="100%">
                <Flex flexWrap="wrap" w="100%">
                  <Box w={{ base: '100%', md: '100%' }}>
                    <Box className="timeline-container">
                      <Flex
                        className="timeline-end "
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Box 
                          className="timeline-dates topImageline"
                          w={{ base: '100%', md: '60%', lg: '58.333%' }}
                          px="1.5rem"
                        >
                          <Image
                            alt={
                              sectionContent?.cards[0]?.thumbnails[0]?.alt || ''
                            }
                            loading="lazy"
                            width={{ base: "100%", md: 730 }}
                            height={{ base: "135px", md: 300 }}
                            decoding="async"
                            objectFit="cover"
                            data-nimg={1}
                            src={sectionContent?.cards[0]?.thumbnails[0]?.url}
                          />
                        </Box>
                        <Box
                          className="col-md timeline-dates"
                          w={{ base: '100%', md: '40%', lg: '41.666%' }}
                          px={{ base: '20px', md: '0px', lg: '1.5rem' }}
                          pt={{ base: '20px', md: '0px', lg: '0' }}
                        >
                          <Heading
                            as="h4"
                            fontFamily="FedraSerifAStdBook, serif"
                            fontSize={{ base: '20px', md: '22px' }}
                            fontWeight="600"
                            mb="0.5rem"
                            pl={{ base: "16px", md: 0 }}
                          >
                            {sectionContent.cards[0].title}
                          </Heading>
                          <StructuredTextParser
                            str={render(
                              sectionContent.cards[0].description.value
                            )}
                            fontSize={{ base: '16px', md: '18px' }}
                            fontWeight="normal"
                            fontStyle="normal"
                            color="#000"
                            fontFamily="FedraSansStd-book"
                            mb="1rem"
                          />
                        </Box>
                      </Flex>
                      <Box className="timeline-continue">
                        {sectionContent?.cards?.map((card, index) => {
                          if (index === 0) {
                            return null;
                          } else if (index === sectionContent.cards.length - 1
                          ) {
                            return null;
                          } else if (index % 2 !== 0) {
                            return (
                              <Flex
                                flexWrap="wrap"
                                className="timeline-left"
                                key={nanoid()}
                                py={{ md: "80px", xl: "50px" }}
                              >
                                <Box
                                  px="1.5rem"
                                  w={{ base: '100%', md: '50%' }}
                                  display={{ base: 'block', md: 'none' }}
                                >
                                  <Box
                                    px="1.5rem"
                                    className="timeline-date"
                                    _before={{
                                      position: 'absolute',
                                      content: '""',
                                      width: '25px',
                                      top: '75px',
                                      height: '25px',
                                      backgroundColor: '#356190',
                                      borderRadius: '50%',
                                      left: '-33px'
                                    }}
                                    _after={{
                                      content: "''",
                                      display: 'block',
                                      position: 'absolute',
                                      width: '250px',
                                      height: '2px',
                                      top: '85px',
                                      background: '#356190',
                                      zIndex: '-1',
                                      left: '-10px'
                                    }}
                                  />
                                </Box>
                                <Box
                                  w={{ base: '100%', md: '50%' }}
                                  px={{ base: '.75rem', md: '1.5rem' }}
                                >
                                  <Box
                                    className="timeline-box"
                                    mr={{
                                      base: '15px',
                                      md: '35px',
                                      xl: '55px',
                                    }}
                                    m={{ base: '15px 0 0', md: '0' }}
                                  >
                                    <Box className="timeline-text">
                                      <Box className="content" >
                                        <Heading
                                          as="h4"
                                          fontFamily="FedraSerifAStdBook, serif"
                                          textAlign={{ base: "left", md: "right" }}
                                          fontSize={{
                                            base: '20px',
                                            md: '22px',
                                          }}
                                          fontWeight="600"
                                          mb="0.5rem"
                                          pl={{ base: "16px", md: 0 }}
                                        >
                                          {card?.title}
                                        </Heading>
                                        <StructuredTextParser
                                          str={render(card.description.value)}
                                          fontSize={{
                                            base: '16px',
                                            md: '18px',
                                          }}
                                          color="#000"
                                          fontWeight="normal"
                                          fontStyle="normal"
                                          fontFamily="FedraSansStd-book"
                                          mb="1rem"
                                          textAlign={{ base: "left", md: "right" }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  px="0.8rem"
                                  w={{ base: '100%', md: '50%' }}
                                  m="auto"
                                //  mt={{ base: "0", md: "17vh" }}
                                //  mb={{ base: "20px", md: "80px" }}
                                >
                                  <Box
                                    className="timeline-date"
                                    _before={{
                                      position: 'absolute',
                                      content: '""',
                                      width: '25px',
                                      top: '101px',
                                      height: '25px',
                                      backgroundColor: '#356190',
                                      borderRadius: '50%',
                                      left: { base: '-22px', md: '-26px' }
                                    }}
                                    _after={{
                                      content: "''",
                                      display: 'block',
                                      position: 'absolute',
                                      width: '250px',
                                      height: '2px',
                                      top: '114px',
                                      background: '#356190',
                                      zIndex: '-1',
                                      left: '-15px',
                                    }}
                                  >
                                    <Image
                                      alt={card?.thumbnails[0]?.alt || ''}
                                      loading="lazy"
                                      width={{ base: "300px", lg: 390 }}
                                      height={{ base: "200px", lg: 235 }}
                                      decoding="async"
                                      data-nimg={1}
                                      style={{ color: 'transparent' }}
                                      src={card?.thumbnails[0]?.url}
                                    />
                                  </Box>
                                </Box>
                              </Flex>
                            );
                          } else {
                            return (
                              <Flex
                                flexWrap="wrap"
                                className="timeline-right"
                                key={nanoid()}
                              >
                                <Box 
                                  w={{ base: '100%', md: '50%' }}
                                  px="0.8rem"
                                  m="auto"
                               //   mt={{ base: "0", md: "17vh" }}
                               //   mb={{ base: "20px", md: "80px" }}
                                >
                                  <Box
                                    className="timeline-date"
                                    _before={{
                                      position: 'absolute',
                                      content: '""',
                                      width: '25px',
                                      top: '101px',
                                      height: '25px',
                                      backgroundColor: '#356190',
                                      borderRadius: '50%',
                                      left: { base: '-22px', md: 'unset' },
                                      right: { base: 'unset', md: '-22px' },
                                    }}
                                    _after={{
                                      content: "''",
                                      display: 'block',
                                      position: 'absolute',
                                      width: '250px',
                                      height: '2px',
                                      top: '86px',
                                      background: '#356190',
                                      zIndex: '-1',
                                      left: { base: '-22px', md: 'unset' },
                                      right: { base: 'unset', md: '-22px' },
                                    }}
                                  >
                                    <Image
                                      alt=""
                                      loading="lazy"
                                      width={{ base: "300px", lg: 390 }}
                                      height={{ base: "200px", lg: 235 }}
                                      decoding="async"
                                      data-img={1}
                                      style={{ color: 'transparent' }}
                                      src={card.thumbnails[0].url}
                                      objectFit="cover"
                                    />
                                  </Box>
                                </Box>
                                <Box 
                                  w={{ base: '100%', md: '50%' }}
                                  px={{ base: '.75rem', md: '1.5rem' }}
                                >
                                  <Box
                                    className="timeline-box"
                                    ml={{ xl: '55px' }}
                                    m={{ base: '15px 0', md: '0' }}
                                  >
                                    <Box className="timeline-text">
                                      <Box className="content">
                                        <Heading
                                          as="h4"
                                          fontFamily="FedraSerifAStdBook, serif"
                                          fontSize={{
                                            base: '20px',
                                            md: '22px',
                                          }}
                                          fontWeight="600"
                                          mb="0.5rem"
                                          pl={{ base: "16px", md: 0 }}
                                        >
                                          {card?.title}
                                        </Heading>
                                        <StructuredTextParser
                                          fontSize={{
                                            base: '16px',
                                            md: '18px',
                                          }}
                                          fontWeight="normal"
                                          fontStyle="normal"
                                          fontFamily="FedraSansStd-book"
                                          mb="1rem"
                                          color="#000"
                                          str={render(card.description.value)}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Flex>
                            );
                          }
                        })}
                      </Box>
                      
                      <Box px="30px" className="timeline-start">
                        <Box className="timeline-date timeline-dates" />
                        <Box className="timeline-launch timeline-launchs">
                          <Box className="timeline-box timeline-boxs">
                            <Box className="timeline-text" >
                              <Image
                                alt=""
                                loading="lazy"
                                width={{ base: "100%", md: 730 }}
                                height={{ base: "135px", md: 300 }}
                                src={
                                  sectionContent?.cards[
                                    sectionContent.cards.length - 1
                                  ].thumbnails[0].url
                                }
                                objectFit="cover"
                              />
                            </Box>
                          </Box>
                          <Box className="iternity-text">
                            <Heading
                              as="h4"
                              fontFamily="FedraSerifAStdBook, serif"
                              fontSize={{ base: '20px', md: '22px' }}
                              fontWeight="600"
                              mb="0.5rem"
                              pt="20px"
                              pl={{ base: "16px", xl: 0 }}
                            >
                              {
                                sectionContent.cards[
                                  sectionContent.cards.length - 1
                                ].title
                              }
                            </Heading>
                            <StructuredTextParser
                              fontSize={{ base: '16px', md: '18px' }}
                              fontWeight="normal"
                              fontStyle="normal"
                              color="#000"
                              fontFamily="FedraSansStd-book"
                              mt="0px"
                              textAlign="center"
                              mb="1rem"
                              str={render(
                                sectionContent?.cards[
                                  sectionContent.cards.length - 1
                                ].description?.value
                              )}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <style global jsx>
        {`
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            .timeline-left .timeline-box {
              margin-left: 0;
            }

            .timeline .timeline-box,
            .timeline .timeline-launch {
              margin: 0;
              padding: 0;
            }
          }

          .timeline .timeline-year {
            margin: 30px 0;
          }

          .timeline .timeline-continue {
            position: relative;
            width: 100%;
            
          }

          .timeline .timeline-continue:after {
            position: absolute;
            content: '';
            width: 6px;
            height: 100%;
            top: 0;
            left: 50%;
            margin-left: -3px;
            background: #356190;
          }

          .timeline div.timeline-left,
          .timeline div.timeline-right .timeline-date {
            text-align: initial;
          }

          .timeline div.timeline-left .timeline-date,
          .timeline div.timeline-right {
            text-align: -webkit-right;
           
          }

          .timeline .timeline-date {
            font-size: 14px;
            font-weight: 600;
            margin: 0;
            position: relative;
          }

          .timeline .timeline-box,
          .timeline .timeline-launch {
            position: relative;
            display: inline-block;
            margin: 15px 0;
            padding: 10px;
            border-radius: 6px;
          }

          .timeline .timeline-launch {
            width: 100%;
            margin: 15px 0;
            padding: 0;
            border: none;
            text-align: center;
            background: transparent;
          }

          .timeline div.timeline-left .timeline-box:after,
          .timeline div.timeline-left .timeline-box:before {
            left: 100%;
          }

          .timeline div.timeline-right .timeline-box:after,
          .timeline div.timeline-right .timeline-box:before {
            right: 100%;
          }

          .timeline .timeline-launch .timeline-box:after,
          .timeline .timeline-launch .timeline-box:before {
            left: 50%;
            margin-left: -10px;
          }

          .timeline .timeline-box:after {
            top: 26px;
            border-color: transparent transparent transparent #fff;
            border-width: 10px;
          }

          .timeline .timeline-box:before {
            top: 25px;
            border-color: transparent transparent transparent #ddd;
            border-width: 11px;
          }

          .timeline div.timeline-right .timeline-box:after {
            border-color: transparent #fff transparent transparent;
          }

          .timeline div.timeline-right .timeline-box:before {
            border-color: transparent #ddd transparent transparent;
          }

          .timeline .timeline-launch .timeline-box:after {
            top: -20px;
            border-color: transparent transparent #ddd;
          }

          .timeline .timeline-launch .timeline-box:before {
            top: -19px;
            border-color: transparent transparent #fff;
            border-width: 10px;
            z-index: 1;
          }

          .timeline .timeline-box .timeline-icon {
            position: relative;
            width: 40px;
            height: auto;
            float: left;
          }

          .timeline .timeline-icon i {
            font-size: 25px;
            color: #4f84c4;
          }

          .timeline .timeline-box .timeline-text {
            position: relative;
            width: calc(100% - 40px);
            float: left;
          }

          .timeline .timeline-launch .timeline-text {
            width: 100%;
          }

          .timeline .timeline-text h3 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 3px;
          }

          .timeline .timeline-text p {
            font-weight: 400;
            padding-bottom:15px
          }

          @media (max-width: 767px) {
            .timeline div.timeline-left .timeline-date,  .timeline div.timeline-right .timeline-date {
              padding:0 10px
            }
            .timeline .timeline-continue:after {
              left: 3px;
              top:100px;
            }

            .timeline div.timeline-left,
            .timeline div.timeline-left .timeline-date,
            .timeline div.timeline-right,
            .timeline div.timeline-right .timeline-date,
            .timeline .timeline-end,
            .timeline .timeline-launch,
            .timeline .timeline-start,
            .timeline .timeline-year {
              text-align: left;
             
            }
            .timeline .timeline-end {
               position:relative;
            }
            .timeline .timeline-end p {
               padding:0 0 15px 0;
            }
           
            .timeline .topImageline {
               position:relative;
               
            }
            .timeline .topImageline:after {
              content: '';
              display: block;
              position: absolute;
              width: 250px;
              height: 2px;
              top: -75px;
              background: #356190;
              z-index: -1;
              left: -15px;
            }
            .timeline .topImageline:after {
               position:relative
            }
            
            .timeline .timeline-end:after {
                position: absolute;
                content: "";
                width: 6px;
                height: 110%;
                left: 3px;
                margin-left: -3px;
                background: #356190;
                top: 72px;
            }
         
            .timeline .timeline-end:before {
                  position: absolute;
                  content: "";
                  width: 25px;
                  top: 48px;
                  height: 25px;
                  background-color: #356190;
                  border-radius: 50%;
                  left: -10px;
            }

            .timeline div.timeline-left .timeline-date,
            .timeline div.timeline-right .timeline-date,
            .timeline .timeline-box,
            .timeline .timeline-launch .timeline-box {
              margin-left: 0;
            }

            .timeline div.timeline-left .timeline-box:after {
              left: -20px;
              border-color: transparent #fff transparent transparent;
            }

            .timeline div.timeline-left .timeline-box:before {
              left: -22px;
              border-color: transparent #ddd transparent transparent;
            }

            .timeline .timeline-launch .timeline-box:after,
            .timeline .timeline-launch .timeline-box:before {
              left: 30px;
              margin-left: 0;
            }

            .timeline-continue .timeline-left {
              flex-direction: column-reverse !important;
            }

            .timeline-date img {
              width: 100%;
            }

            .timeline-left .timeline-box {
              margin-right: 0;
            }
          }

          .timeline-date {
            width: 100%;
            border-radius: 15px;
          }

          .timeline-dates img {
            width: 100%;
          }

          .timelinebox img {
            border-radius: 15px;
          }

          .timeline-dates h4 {
            font-weight: 600;
          }

          .timeline-right .timeline-box {
            margin-left: 55px;
          }

          .timeline-left .timeline-box {
            margin-right: 55px;
          }

          .timeline-text .content {
            text-align: start;
          }

          .timeline-boxs {
            background: unset !important;
            border: unset !important;
            padding: 0 !important;
            margin: 0 !important;
          }

          .timeline-boxs:after,
          .timeline-boxs:before,
          .timeline-datess {
            display: none !important;
          }

          .timeline-launchs {
            margin: 0 !important;
          }

          .timeline-left .timeline-box {
            margin-left: 0;
          }

          .timeline-left,
          .timeline-right {
            margin-bottom: 0;
          }

          .timeline .timeline-box .timeline-text {
            width: 100%;
          }

          .timelinebox {
            padding-top: 0;
          }

          .col-md-5.timeline-dates {
            padding-top: 30px;
            padding-left: 35px;
          }

          .timeline .timeline-box,
          .timeline .timeline-launch {
            margin: 15px 0 0;
          }

          .timeline-right .timeline-date:before {
            position: absolute;
            content: '';
            width: 25px;
            top: 75px;
            height: 25px;
            background-color: #356190;
            border-radius: 50%;
            right:-25px
          }

          @media only screen and (min-width: 100px) and (max-width: 299px) {
            .timeline-continue .timeline-left {
              flex-direction: column-reverse !important;
            }

            .timeline-date img {
              width: 100%;
            }

            .timeline-left .timeline-box {
              margin-right: 0;
            }
          }
          .timeline div.timeline-left {
            text-align: -webkit-right;
          }
          .timeline-date,
          .timeline div.timeline-right {
            text-align: -webkit-left;
          }
        `}
      </style>
    </Box>
  );
};

export default ZigZagItenary;
