/* Built In Imports */
import { useState } from 'react';
/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  chakra,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import {
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@sw/components/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the Faq component.
 *
 * @param faqSection.faqSection
 * @param {Array} faqSection - Main FAQ data.
 * @param faqSection.region
 * @param faqSection.lang
 * @param faqSection.bgColor
 * @param faqSection.color
 * @returns {ReactElement} FaqSection component.
 */
const FaqExpandedSection = ({ faqSection, region, lang, bgColor, color }) => {
  const [isOpen, SetIsOpen] = useState(false);
  return (
    <Flex justify="center">
      <Box
        w={{ base: '90%', xl: '80%' }}
        maxW="1330px"
        position="relative"
        h="auto"
        m={{ base: '60px auto', xl: '137px 80px' }}
        p={{ base: '55px 10px', md: '50px 0px', xl: '90px 150px 70px 150px' }}
        borderRadius="15px"
        boxShadow="inset 0px 8px 13px 0 rgba(0, 0, 0, 0.28)"
        bg="#f9f9f9"
      >

        {faqSection?.faqs.map((faq, indx, array) => {
          return (

            <Accordion allowToggle allowMulitple={false} key={indx} >
              {indx === array.length - 1 ?
                <AccordionItem
                  borderBottom="none"
                  borderTop="none"
                  lineHeight="1.61"
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        pb="0.8rem"
                        fontFamily="FedraSansStd-medium"
                        borderTop="none"
                        color="#fff"
                        _hover={{ background: 'none' }}
                        _focus={{ shadow: 'transparent' }}

                      >
                        <VerticalTimelineElement
                          contentArrow={{
                            border: 'none',
                            borderRight: '0px',
                          }}
                          contentStyle={{
                            background: '#f9f9f9',
                            color: '#000',
                            pt: '30px',
                            height: 'auto',
                          }}
                          position="right"
                          iconStyle={{
                            background: '#0e72a6',
                            color: '#0e72a6',
                            textAlign: 'center',
                          }}
                          icon={
                            isExpanded ? (
                              <img
                                src={'/assets/images/TimeLineDownArrow.png'}
                                className="arrow-down"
                              />
                            ) : (
                              <img
                                src={'/assets/images/TimeLineRightArrow.png'}
                                className="arrow-down"
                              />
                            )
                          }
                        >
                          <chakra.div
                            flex="1"
                            fontSize={{
                              lg: '20px',
                              md: '18px',
                              base: '16px',
                            }}
                            fontFamily="FedraSansStd-A-medium"
                            fontWeight="500"
                            lineHeight={{ base: '1.5em' }}
                            color="#000000"
                            textAlign="start"
                            mt={{
                              md: '-10px',
                              lg: '-20px',
                              sm: '-12px',
                              base: '-15px',
                            }}
                            ml="0px"
                          >
                            {faq.question}
                          </chakra.div>

                          <AccordionPanel
                            pb={2}
                            fontSize="1.1rem"
                            fontFamily="FedraSansStd-book"
                            lineHeight="1.61"
                          >
                            <Box
                              display="block"
                              width="100%"
                              lineHeight="1"
                              fontSize={{ sm: '16px', lg: '18px' }}
                              color="#28231e"
                            >
                              <Box className="commanFaqs" fontWeight="normal">
                                <StructuredTextParser
                                  className="faqAnswer"
                                  ml={{
                                    sm: '-30px',
                                    base: '-30px',
                                    md: '-15px',
                                  }}
                                  fontFamily="FedraSansStd-Book"
                                  str={render(faq.answer)}
                                  region={region}
                                  lang={lang}
                                  color="#000000"
                                />
                              </Box>
                            </Box>
                          </AccordionPanel>
                        </VerticalTimelineElement>
                      </AccordionButton>
                    </>
                  )}
                </AccordionItem> :
                <AccordionItem
                  borderBottom="none"
                  borderTop="none"
                  lineHeight="1.61"
                  className="blueLineItem"
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        pb="0.8rem"
                        fontFamily="FedraSansStd-medium"
                        borderTop="none"
                        color="#fff"
                        _hover={{ background: 'none' }}
                        _focus={{ shadow: 'transparent' }}

                      >
                        <VerticalTimelineElement
                          contentArrow={{
                            border: 'none',
                            borderRight: '0px',
                          }}
                          contentStyle={{
                            background: '#f9f9f9',
                            color: '#000',
                            pt: '30px',
                            height: 'auto',
                          }}
                          position="right"
                          iconStyle={{
                            background: '#0e72a6',
                            color: '#0e72a6',
                            textAlign: 'center',
                          }}
                          icon={
                            isExpanded ? (
                              <img
                                src={'/assets/images/TimeLineDownArrow.png'}
                                className="arrow-down"
                              />
                            ) : (
                              <img
                                src={'/assets/images/TimeLineRightArrow.png'}
                                className="arrow-down"
                              />
                            )
                          }
                        >
                          <chakra.div
                            flex="1"
                            fontSize={{
                              lg: '20px',
                              md: '18px',
                              base: '16px',
                            }}
                            fontFamily="FedraSansStd-A-medium"
                            fontWeight="500"
                            lineHeight={{ base: '1.5em' }}
                            color="#000000"
                            textAlign="start"
                            mt={{
                              md: '-10px',
                              lg: '-20px',
                              sm: '-12px',
                              base: '-15px',
                            }}
                            ml="0px"
                          >
                            {faq.question}
                          </chakra.div>

                          <AccordionPanel
                            pb={2}
                            fontSize="1.1rem"
                            fontFamily="FedraSansStd-book"
                            lineHeight="1.61"
                          >
                            <Box
                              display="block"
                              width="100%"
                              lineHeight="1"
                              fontSize={{ sm: '16px', lg: '18px' }}
                              color="#28231e"
                            >
                              <Box className="commanFaqs" fontWeight="normal">
                                <StructuredTextParser
                                  className="faqAnswer"
                                  ml={{
                                    sm: '-30px',
                                    base: '-30px',
                                    md: '-15px',
                                  }}
                                  fontFamily="FedraSansStd-Book"
                                  str={render(faq.answer)}
                                  region={region}
                                  lang={lang}
                                  color="#000000"
                                />
                              </Box>
                            </Box>
                          </AccordionPanel>
                        </VerticalTimelineElement>
                      </AccordionButton>
                    </>
                  )}
                </AccordionItem>}

            </Accordion>

          );
        })}

        <style jsx global>{`
          .blueLineItem {
            position:relative
          }
          .blueLineItem:after {
            content:'';
            height:100%;
            width:5px;
            position:absolute;
            top:45px;
            left:34px;
            background:#0e72a6;
          }
            .commanFaqs ul {
             margin:20px

            }
            .faqAnswer {
              margin-top: -10px !important;
              
            }
           
            .vertical-timeline-element-icon img{
              margin-top: 13px; 
              margin-bottom: 13px;
               margin-left:11.5px;
               width: 18px;
               height: 15px;
            }

            .vertical-timeline-element-content {
              box-shadow: 0 0px 0 #ddd;

            }

            .vertical-timeline-element-content-arrow {
              border:none !important;
            }
            .vertical-timeline-element-icon {
              -webkit-box-shadow: 0 0 0 0px white, inset 0 1px 0 rgb(0 0 0 / 0%), 0 0px 0 0px rgb(0 0 0 / 0%);
               box-shadow: 0 0 0 0px white, inset 0 0px 0 rgb(0 0 0 / 0%), 0 0px 0 0px rgb(0 0 0 / 0%);

            }
          
          `}</style>
      </Box>
    </Flex>
  );
};

export default FaqExpandedSection;
