/* Built In Imports */
/* External Imports */
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@sw/components/Utility/StructuredTextParser';

/**
 * Renders the Zigzag1Row Card component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} CardZigzagGroup Card component
 */

const ContentImageBox = ({ sectionContent, section }) => {
  return (
    <>
      <Flex maxW="1170px" w="full" mx="auto">
        <Box
          display="flex"
          gridGap={{ base: '0', md: '20px' }}
          justifyContent="space-between"
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          flexDir={{ base: 'column', md: 'row' }}
          mx="auto"
          pt="20px"
          w={{ lg: 'full', md: 'full', base: '95%' }}
          px={{ base: 'auto', lg: '20px' }}
        >
          <Box w={{ base: '100%', md: '90%' }} display="flex" flexDir='column' mx="auto"
          >
            <Text
              as="h2"
              lineHeight={{ base: "40px", xl: "1.5" }}
              fontWeight="400"
              fontSize={{ base: "24px", xl: "26px" }}
              mb={{ lg: '0.5rem', base: '0.9rem' }}
              fontFamily="FedraSansStd-A-medium"
            >
              {sectionContent[0].text}
            </Text>
            <StructuredTextParser
              fontSize={{ md: "16px", xl: "18px" }}
              fontFamily="'FedraSansStd-book'"
              color="#000"
              mb="1rem"
              str={render(sectionContent[1].body)}
              maxW="none"
              lineHeight="28px"
              mt="none"
              p="0px"
              className="textP"
            />          
          </Box>
          <Box
            pt={{ base: '10px' }}
            mx={{ base: 'auto' }}
          >
            <Image
              borderRadius="15px"
              alt=""
              src={sectionContent[2]?.image?.url}
              boxShadow="15.7px 19.4px 13px 0 rgba(0, 0, 0, 0.18)"
              //  src={item?.image?.url}
              maxW="650px"
              width="100%"
              h='auto'
              maxH="600px"
            />
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default ContentImageBox;
