/* External Imports */
import { Box } from '@chakra-ui/react';
// import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import DesktopDARTable from './DesktopDARTable';
// import MobileDARTable from './MobileDARTable';


export default function DatesAndRegistrations({ sectionContent }) {
  return (
    <Box
      boxShadow="0px 0.9625px 4.33594px 0px rgba(0, 0, 0, 0.02), 0px 4.4px 12.1875px 0px rgba(0, 0, 0, 0.03), 0px 11.1375px 32.69531px 0px rgba(0, 0, 0, 0.04), 0px 22px 75px 0px rgba(0, 0, 0, 0.06)"
      background="white"
      maxW={{ base: '100%', sm: '100%', md: '96%', lg: '96%', xl: 1173}}
      m="0 auto"
      w="100%"
     // m="20px auto"
      //px="20px"
      borderRadius={{ base: "0", md: "12px" }}
      p="20px 0 1px "
    >
      {sectionContent?.map((secCon, i) => {
        if (secCon._modelApiKey === 'table_editor') {
          return (
            <DesktopDARTable sectionContent={secCon} key={i} />
          );
        }
        else if (secCon._modelApiKey === 'section_title') {
          return (
            <Box
              key={i}
              pl={{ base: "20px", md: "9%" }}
              color="#34628F"
              fontSize={{ base: "18px", md: "30px" }}
              fontFamily="FedraSansStd-A-medium"
            >
              {secCon.titleText}
            </Box>
          );
        }
      })}
    </Box>
  );
}
