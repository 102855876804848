/* Built In Imports */
/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Styles */
/* Services */

/**
 * Returns Quote Component
 *
 * @param sectionContent
 */

const QuoteImageSignature = ({ sectionContent }) => {
  console.log(sectionContent.quote.replace(/"/, ''), 'QuoteImageSignature');
  const quotes = sectionContent.quote?.split('.');
  return (
    <Box
      display="flex"
      flexDir="column"
      maxW={1330}
      mx="auto"
      mb="50px"
      width="100%"
      color="#000000"
      alignItems="center"
      px="20px"
    >
      <Box
        pt="74px"
        fontSize={{ base: '18px', md: '26px' }}
        fontFamily="FedraSerifAStdBook"
        maxW="700px"
        textAlign="center"
        fontWeight="550"
      >
        {/* <Text pl="40px">
          {' '}
          "A pilgrimage is not a conquest, it is a surrender.
        </Text>
        <Text pl="20px">It is a way of getting yourself out of the way.</Text>
        <Text>If you do not budge, it is a way of wearing yourself out."</Text> */}
        {quotes.map((quote, index) => {
          return (
            <Text
              key={nanoid()}
              display={
                index === quotes.length - 1
                  ? 'none'
                  : index === quotes.length - 2
                    ? 'inline-block'
                    : 'block'
              }
              textAlign="center"
            >
              {quote}
              {index !== quotes.length - 1 && '.'}
              {index === quotes.length - 2 && <>&quot;</>}
            </Text>
          );
        })}
      </Box>
      <Box pt={{ base: '20px', md: '40px' }}>
        <LazyLoadImageComponent
          src="/assets/images/signature_name.png"
          alt="sadhguru's signature"
          h={{ base: '60px', lg: '100px' }}
          w="auto"
        />
      </Box>
    </Box>
  );
};

export default QuoteImageSignature;
