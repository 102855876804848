/* Built In Imports */
/* External Imports */
import { Box, Heading, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Services */
/* Styles */

/**
 * Renders the SubduingSection Card component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} SubduingSection Card component
 */

const SubduingSection = ({ sectionContent }) => {
  // console.log('sectionContent', sectionContent);
  return (
    <Box mx="auto" maxW="1170px" px={{ base: "32px", lg: '16px', xl: '0px' }}>
      <Box display="flex" flexDirection={{ base: 'column', lg: 'row' }} py="20px" gridGap="30px" justifyContent="space-between">
        <Box w={{ base: "100%", lg: "60%" }}>
          <LazyLoadImageComponent
            src={sectionContent[0].simpleCards[0].rectangularImage?.url}
            alt=''
            width="100%"
            height="auto"
            borderRadius='10px'
          />

        </Box>
        <Box
          w={{ base: "100%", lg: "40%" }}
        >
          <Heading
            as="h2"
            textAlign={{ base: "center", lg: 'left' }}
            fontSize={{ base: "44px", xl: '54px' }}
          >
            {sectionContent?.[0]?.title[0]?.titleText}
          </Heading>
          <Text
            textAlign={{ base: "center", lg: 'left' }}
            fontSize="36px"
            fontWeight='semibold'
          >
            {sectionContent?.[0]?.title[0]?.titleSubtext}
          </Text>
          <Text
            fontFamily='FedraSansStd-book'
            lineHeight="28px"
          >
            {sectionContent?.[0]?.simpleCards[0]?.descriptionText}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default SubduingSection;
