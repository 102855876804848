/* Built In Imports */

/* External Imports */
import { Box, Center, Flex, Image, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@sw/components/Utility/StructuredTextParser';

/* Services */
/* Styles */

/**
 * Renders the Zigzag1Row Card component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} Offerings Card component
 */

const Offerings = ({ sectionContent }) => {
  return (
    <Box
      pt="20px"
      mb={{ base: '20px', md: "250px" }}
    >
      <Center>
        <Text
          as="h2"
          fontSize={{ md: '40px', base: '40px' }}
          fontFamily="FedraSansStd-A-medium"
          mt="10px"
        >
          {sectionContent[0]?.text}
        </Text>
      </Center>
      <Center pb="20px">
        <Text fontSize={{ md: '20px', base: '16px' }}
          fontFamily="FedraSansStd-A-medium">
          {sectionContent[1]?.text}
        </Text>
      </Center>
      <Flex
        maxW="1330px"
        alignItems="center"
        justifyContent="center"
        mx="auto"
        gridGap={{ base: '20px', md: '3%', xl: '30px' }}
        px={{ base: '16px', xl: '3%' }}
        flexWrap={{ base: 'wrap', md: 'wrap', xl: 'nowrap' }}
      >
        {sectionContent[2]?.cards?.map(card => {
          return (
            <Box
              width={{ base: '100%', md: '20%', lg: '22%' }}
              position="relative"
              key={nanoid()}
              height={{ base: 'auto', md: 'auto', lg: 'auto' }}
              minHeight="200px"
            >
              {/* <NextLink
                href={`sacred-walks/${card?.buttonLink}`}
                passHref
                legacyBehavior
              > */}
              <Link href={`sacred-walks/${card?.buttonLink}`}>
                <Box
                  w='100%' h='auto'
                  transition='0.3 sec'
                  boxShadow="8px 6px 16px 0px rgb(0 0 0 / 60%)"
                  position={{ base: 'relative', md: 'absolute' }}
                  borderRadius="10px"
                  _hover={{
                    boxShadow: "0 4px 30px 0 rgba(4,32,66,0.3)",
                    transform: "scale(0.99)",
                    transition: "all 0.2s linear"
                  }}
                >
                  <Image
                    width="100%"
                    height="100%"
                    objectFit={{ base: '100% 60%', md: 'cover' }}
                    borderRadius="10px"
                    objectPosition="top"
                    alt=""
                    src={card?.thumbnail?.url}
                    display={{ base: 'none', md: 'block' }}
                    _hover={{
                      boxShadow: "0 4px 30px 0 rgba(4,32,66,0.3)",
                      transform: "scale(0.99)",
                      transition: "all 0.2s linear"
                    }}
                  />
                  <Image
                    width="100%"
                    height="100%"
                    objectFit={{ base: '100% 60%', md: 'cover' }}
                    borderRadius="10px"
                    boxShadow="8px 6px 16px 0px rgb(0 0 0 / 60%)"
                    objectPosition="top"
                    alt=""
                    src={card?.mobileImage?.url}
                    display={{ base: 'block', md: 'none' }}
                    _hover={{
                      boxShadow: "0 4px 30px 0 rgba(4,32,66,0.3)",
                      transform: "scale(0.99)",
                      transition: "all 0.2s linear"
                    }}
                  />
                  <Text
                    position="absolute"
                    right="20px"
                    top="20px"
                    fontSize="16px"
                    color="white"
                    fontFamily="FedraSansStd-book"
                    _hover={{
                      boxShadow: "0 4px 30px 0 rgba(4,32,66,0.3)",
                      transform: "scale(0.99)",
                      transition: "all 0.2s linear"
                    }}
                  >
                    {card?.sessions}
                  </Text>
                  <Box
                    backgroundColor="rgb(0,0,0,0.4)"
                    fontFamily="'FedraSansStd-medium'"
                    position="absolute"
                    bottom="0"
                    width="100%"
                    left="0"
                    color="white"
                    borderRadius="0 0 10px 10px"
                    p={{ base: "15px 20px", sm: "20px", md: "20px" }}
                    _hover={{
                      boxShadow: "0 4px 30px 0 rgba(4,32,66,0.3)",
                      transform: "scale(0.99)",
                      transition: "all 0.2s linear"
                    }}
                  >
                    <Text
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      fontSize={{
                        '2xl': '24px',
                        xl: '24px',
                        lg: '24px',
                        md: '24px',
                        base: '20px',
                      }}
                      mb={{ base: '5px', md: '0' }}
                      fontFamily="FedraSansStd-medium"
                    >
                      {card.title}
                    </Text>
                    <Box
                      noOfLines={2}
                      fontSize={{ lg: '26px', md: '24px', base: '18px' }}
                      fontFamily="FedraSansStd-book"
                    >
                      <StructuredTextParser
                        str={render(card.description)}
                        region={''}
                        lang={''}
                        fontFamily="FedraSansStd-medium"
                        mt="0"
                        p="0"
                        color="#fff"
                        fontSize="13px"
                        lineHeight="1.2rem"
                        maxW="none"
                      />
                    </Box>
                  </Box>
                </Box>
              </Link>
              {/* </NextLink> */}
            </Box>
          );
        })}
      </Flex>
      <style global jsx>
        {`
          .desc .p {
            overflow: hidden;
            
          }
        `}
      </style>
    </Box>
  );
};

export default Offerings;
