/* Built In Imports */
/* External Imports */
import { Box, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Styles */
/* Services */

/**
 *
 * @param {object} sectionContent
 * @returns
 */

const IshaMaintainsText = ({ sectionContent }) => {
  return (
    <Box px={{ base: '20px', lg: '20px', xl:"0" }}>
      <Box 
        bg="#fff"
        // margin="20px"
        height={{ base: 'auto', md: '118px' }}
        m="20px auto"
        maxW={{ base: '100%', sm: '100%', md: '100%', lg: '100%', xl: 1173}}
        boxShadow="0 0 30.1px 1.9px rgba(0, 0, 0, 0.16)"
        borderRadius="10px"
        className="insha maintain box"
        display="flex"
        w="100%"
       // px="20px"
        py="30px"
      >
        <Box
          w="100%"
          mx="auto"
          pos="relative"
          marginTop="auto"
          px="15px"
          marginBottom="auto"
        >
          <Text
            fontFamily="FedraSansStd-book"
            fontSize="16px"
            textAlign="center"
            borderRadius="10px"
          >
            {sectionContent?.text}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default IshaMaintainsText;
