/* Built In Imports */
/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SimpleButton from '@sw/components/Buttons/SimpleButton';
import SingleImage from '@sw/components/Img/SingleImage';
import StructuredTextParser from '@sw/components/Utility/StructuredTextParser';

/* Services */
/* Styles */

/**
 * Renders the YatraInfoSection component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} YatraInfoSection Card component
 */

const YatraInfoSection = ({ sectionContent, region, language }) => {
  return (
    <Box mt='60px' py="20px" background="#f3f3f3">
      <Box maxW="1170px" w="full" mx="auto">
        <Box
          display="flex"
          gridGap={{ base: '0', md: '60px' }}
          justifyContent="space-between"
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          flexDir={{ base: 'column', md: 'row' }}
          mx="auto"
          py="20px"
          w={{ lg: 'full', md: 'full', base: '95%' }}
          alignItems="center"
          px="16px"
        >
          <Image
            borderRadius="10px"
            alt=""
            src={sectionContent[0]?.image?.url}
            boxShadow="15.7px 19.4px 13px 0 rgba(0, 0, 0, 0.18)"
            h={{ base: '370px', md: '400px', lg: 'auto' }}
            width={{ base: 'full', md: '90%', lg: '60%', xl: 'full' }}
            mx="auto"
            objectFit="cover"
            mb={{ base: '30px', md: 0 }}
          />
          <Box
            w={{ base: '100%', md: '420px' }}
            display="flex"
            alignItems="center"
            textAlign="center"
            mx="auto"
            flexDir="column"
          >
            <StructuredTextParser
              fontSize="20px"
              fontFamily="FedraSerifAStdBook"
              lineHeight={{ base: '37.5px', md: '35px', lg: '35px' }}
              color="#000000"
              fontWeight="400"
              textAlign="center"
              mt="0px"
              str={render(sectionContent[1].body)}
            />
            <Text
              fontFamily='FedraSansStd-A-medium'
              pt="10px"
            >
              {sectionContent[2]?.text}
            </Text>
            
          </Box>
        </Box>
        <Box w="full" px={{ base: "16px", lg: "10px" }} >
          <StructuredTextParser
             fontSize={{ md: "16px", xl: "18px" }}
            fontFamily="'FedraSansStd-book'"
            color="#000"
            mb="1rem"
            str={render(sectionContent[3].body)}
            maxW="none"
            lineHeight="28px"
            className="textP"
          />
        </Box>
      </Box>

      <SingleImage
        sectionContent={sectionContent[4]}
        region={region}
        lang={language}
      />

      <SimpleButton
        style={{
          buttonStyle: 'Sacred-Walks-Blue',
          buttonText: sectionContent[5]?.buttonText,
          linkUrl: sectionContent[5]?.linkUrl,
        }}
        
        mx="auto"
      />

      <style jsx global>
        {`
        .textP p{
          padding-bottom:16px
        }
        `}
      </style>
    </Box>
  );
};

export default YatraInfoSection;
