/* Built In Imports */

/* External Imports */
import { Box, Flex, Link } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */
/* Styles */

/**
 * Renders the OtherYatras Card component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} OtherYatras Card component
 */

const OtherYatras = ({ sectionContent, section }) => {
  return (
    <>
      <Flex
        h={{ base: '75px', md: '120px', xl: '225px' }}
        color="white"
        w="100%"
        py="36px"
        bg="#35618f"
        fontSize={{ base: '20px', md: '40px' }}
        pt={{ base: '10px', xl: '40px' }}
        justify="center"
        fontFamily="FedraSansStd-A-medium"
      >
        {sectionContent[0]?._modelApiKey === 'single_line_text' &&
          sectionContent[0]?.text}
      </Flex>
      <Box
        pos="relative"
        maxW="1110px"
        px={{ base: '8px', md: '16px', lg: '80px' }}
        mx="auto"
      >
        <Flex
          color="white"
          justify="center"
          position="relative"
          fontFamily="FedraSansStd-A-medium"
          gridGap={{ base: '10px', md: '30px' }}
          top={{ base: '-20px', md: '-40px', xl: '-90px' }}
        >
          {sectionContent[1]?.cards?.map((card, index) => {
            return (
              <Link _focus={{
                boxShadow: "none"
              }}
                href={card?.buttonLink}

                _active={{
                  boxShadow: "none"
                }}>
                {/* <NextLink
                key={nanoid(5)}
                href={card?.buttonLink}
                passHref
                legacyBehavior
              > */}
                <Box
                  pos="relative"
                  h={{
                    base: '75px',
                    sm: '96px',
                    md: '150px',
                    lg: '190px',
                    xl: '220px',
                    '2xl': '230px',
                  }}
                  p="5px"
                  w={{
                    base: '75px',
                    sm: '96px',
                    md: '150px',
                    lg: '190px',
                    xl: '220px',
                    '2xl': '230px',
                  }}
                  bgImage={card?.thumbnail?.url}
                  bgSize="120%"
                  bgRepeat="no-repeat"
                  bgPos="top"
                  boxShadow={{ base: "8px 6px 16px 0px rgb(0 0 0 / 60%)", md: "8px 6px 16px 0px rgb(0 0 0 / 60%)" }}
                  _hover={{
                    boxShadow: "0 4px 30px 0 rgba(4,32,66,0.3)",
                    transform: "scale(0.99)",
                    transition: "all 0.2s linear"
                  }}
                  borderRadius="1rem"

                >
                  <Flex
                    align="center"
                    justify="center"
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    bgColor="rgba(0, 0, 0, 0.5)"
                    _hover={{ boxShadow: 'none' }}
                    borderRadius="1rem"
                    textAlign="center"
                    p="16px"
                    fontSize={{
                      base: '12px',
                      md: '16px',
                      lg: '20px',
                      xl: '26px',
                    }}
                    fontFamily="FedraSansStd-medium"
                  >
                    {card?.title}
                  </Flex>
                </Box>
                {/* </NextLink> */}
              </Link>
            );
          })}
        </Flex>
      </Box>
    </>
  );
};

export default OtherYatras;
