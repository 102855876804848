/* Built In Imports */

/* External Imports */
import { Box, Flex, Icon, Image, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@sw/components/Utility/StructuredTextParser';

/* Services */
/* Styles */

/**
 * Renders the WhyGoPilgrimage Card component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} WhyGoPilgrimage Card component
 */

const WhyGoPilgrimage = ({ sectionContent, section }) => {
  return (
    <Flex
      pos="relative"
      my={{ base: '20px', xl: "96px" }}
      pl={{ xl: '45px' }}
      alignItems="center"
      justify={{ base: 'center', xl: 'flex-end' }}
      mx="auto"
      maxW="1330px"
    >
      <Flex
        display={{ base: 'none', xl: 'flex' }}
        gridGap="44px"
        pos={{ base: 'static', xl: "absolute" }}
        left="0px"
        p={{ lg: '31px 35px 35px 27px' }}
        maxH={{ xl: '340px' }}
        maxW="615px"
        borderRadius="25px"
        boxShadow={{ base: 'none', xl: '0px 5px 6px #00000066' }}
        bg={{ base: "transparent", xl: "white" }}
        flexShrink="0"
        flexDir={{ base: 'column', xl: 'row' }}
        fontFamily="FedraSansStd-A-medium"
      >
        {/*<Image
          alt=""
          src={sectionContent[0]?.image?.url}
          borderRadius={{ lg: '25px' }}
          maxW={{ xl: '299px' }}
          objectFit="cover"
          objectPosition="left top"
        ></Image>*/}
        <Box
          alignSelf={{ base: 'center', lg: 'initial' }}
          w={{ base: '90vw', lg: 'initial' }}
          boxShadow={{
            base: '0px 5px 6px #00000066',
            md: 'none'
          }}
          mt='-20px'
        >
          <Text
            as="h2"
            fontSize="30px"
            textAlign={{ base: 'center', xl: "left" }}
          >
            {sectionContent[2].text}
          </Text>
          <Box
            padding={{ base: '20px', md: ' 28px 83px 22px 76px', lg: '0px 0 0 0' }}
          >
            <StructuredTextParser
              str={render(sectionContent[3].body)}
              region={''}
              lang={''}
              fontFamily="FedraSansStd-book"
              color="#000"
              fontSize="16px"
              lineHeight="28px"
              className="textPadding"
              mt='10px'
            />
            <Box display='flex' flexDir='row'>
              <Icon viewBox='0 0 200 200' color='#35618f' mt='15px'>
                <path
                  fill='currentColor'
                  d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                />
              </Icon>
              {/* <NextLink href={`sacred-walks/${sectionContent[4]?.linkUrl}`} passHref legacyBehavior> */}
              <Link
                href={`sacred-walks/${sectionContent[4]?.linkUrl}`}
                textDecoration="none"
                _hover={{ textDecoration: 'none', border: 'none' }}
                _focus={{ textDecoration: 'none', border: 'none' }}
                _active={{ textDecoration: 'none', border: 'none' }}
                borderBottom="2px solid #35618f"
                mt="10px"
                ml="5px"
              >
                {sectionContent[4]?.buttonText}
              </Link>
              {/* </NextLink> */}
            </Box>
          </Box>
        </Box>
      </Flex>


      {/* Mobile */}

      <Flex
        display={{ base: 'flex', xl: 'none' }}
        fontFamily="FedraSansStd-A-medium"
        width="100%"
        borderRadius={{ base: '0px' }}
      >
        <Box
          alignSelf={{ base: 'center', lg: 'initial' }}
          w="full"
        >
          <Text
            as="h2"
            fontSize="24px"
            my="30px"
            px={{ base: "16px", md: "0px" }}
            lineHeight="1.2em"
            textAlign={{ base: 'center', xl: "left" }}
          >
            {sectionContent[2].text}
          </Text>
          <Image
            alt=""
            src={sectionContent[0]?.mobileImage?.url}
            borderRadius='0'
            w="full"
            maxH='550px'
            h={{ base: "full", lg: "370px" }}
            objectFit="cover"
            objectPosition="top"
          />
          <Box
            padding={{ base: '10px', md: '20px' }}
            boxShadow='0px 5px 6px #00000066'
            bg="#fff"
            mx={{ base: "10px", md: 'auto' }}
            mt="-25px"
            position="relative"
            borderRadius="20px"
            width={{ base: "auto", md: '80%' }}
          >
            <StructuredTextParser
              str={render(sectionContent[3].body)}
              region={''}
              lang={''}
              fontFamily="FedraSansStd-book"
              color="#000"
              fontSize={{ md: '16px', xl: '18px' }}
              lineHeight="24px"
              className="textPadding"
            />
            <Box display='flex' flexDir='row' m="0 10px 20px">
              <Icon viewBox='0 0 200 200' color='#35618f' mt='15px'>
                <path
                  fill='currentColor'
                  d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                />
              </Icon>
              {/* <NextLink href={`sacred-walks/${sectionContent[4]?.linkUrl}`} passHref legacyBehavior> */}
              <Link
                href={`sacred-walks/${sectionContent[4]?.linkUrl}`}
                textDecoration="none"
                _hover={{ textDecoration: 'none', border: 'none' }}
                _focus={{ textDecoration: 'none', border: 'none' }}
                _active={{ textDecoration: 'none', border: 'none' }}
                borderBottom="2px solid #35618f"
                mt="10px"
                ml="5px"
              >
                {sectionContent[4]?.buttonText}
              </Link>
              {/* </NextLink> */}
            </Box>
          </Box>
        </Box>
      </Flex>
      <Box
        height="550px"
        display={{ base: "none", xl: 'flex' }}
        bgImage={sectionContent[1]?.image?.url}
        bgRepeat="no-repeat"
        borderRadius="25px 0 0 25px"
        bgSize="cover"
        w="60%"
        flexShrink="0"
      >
      </Box>
      <style jsx global>
        {
          `
            .textPadding p { 
              padding-bottom: 15px;
            }
            .textPadding p a {
              text-decoration:underline
            }

            @media (max-width:767px) {
              .textPadding {
                padding:0px;
              }
            }
            `
        }

      </style>
    </Flex>
  );
};

export default WhyGoPilgrimage;
