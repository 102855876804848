/* Built In Imports */
import { useEffect, useRef, useState } from 'react';
/* External Imports */
import {
  Box,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@sw/components/Utility/StructuredTextParser';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders the Sharings Card component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} CardZigzagGroup Card component
 */

const Sharings = ({ sectionContent, section }) => {
  const sliderRef = useRef(null);
  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const [hovered, setHovered] = useState(false);
  const current = sectionContent[2].testimonials[testimonialIndex];
  useEffect(() => {
    if (
      sliderRef.current
    ) {

      if (hovered) {
        sliderRef.current.slickPause()
      }
      else {
        sliderRef.current.slickPlay()
      }
    }

  }, [hovered]);
  // const prefersReducedMotion = usePrefersReducedMotion();
  const slickSettings = {
    dots: false,
    speed: 4000,
    slidesToShow: 3,
    arrows: false,
    swipeToSlide: true,
    adaptiveHeight: false,
    infinite: true,
    autoplay: true,
    centerMode: false,
    pauseOnHover: true,
    afterChange: i => {
      setTestimonialIndex(i ? (i - 1) : (sectionContent[2].testimonials.length - 1));
      // console.log('i', i, i ? (i - 1) : (sectionContent[2].testimonials.length - 1));
    },

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          centerMode: true
        },
      }
    ]
  };
  return (
    <Box
      mt="70px"
      mb="15px"
      mx="auto"
      maxW="1330px"
      fontFamily="FedraSansStd-A-medium"
    >
      <Box textAlign={{ base: 'center', xl: 'left' }}>
        <Text
          fontSize={{ base: '28px', md: '2rem', }}
          fontWeight="500"
          mb={{ base: '10px', md: '0' }}
          as="h2"
          textTransform="capitalize"
          lineHeight="1.5em"
          fontFamily="FedraSansStd-A-medium"
        >
          {sectionContent[0].text}
        </Text>
        <Text
          as="p"
          fontSize={{ md: '20px', base: '17px' }}
          fontFamily="FedraSansStd-A-medium"
        >
          {sectionContent[1].text}
        </Text>
      </Box>

      <Flex
        mt={{ base: "30px", md: "50px" }}
        gridGap="15px"
      >
        <Box
          w="45%"
          h="450px"
          alignSelf="flex-end"
          display={{ base: 'none', xl: 'block' }}
        >
          <Image
            w='100%'
            opacity="0"
            // animation={animation}
            animation="glow 0.9s ease-in-out forwards"
            alt={current?.phot?.alt || ''}
            src={current?.photo?.url}
            borderRadius="10px"
            h="full"
            objectFit="cover"
            cursor="pointer"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          />
        </Box>

        <Flex
          flexDir="column"
          w={{ xl: '55%' }}
          pr={{ base: '0px', xl: "0" }}
          maxW={{ base: '100%', xl: '980px' }}
          gridGap="2rem"
          justify="space-between"
        >
          <Box display={{ base: 'none', xl: 'block' }} cursor="pointer" onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}>
            <Text as="h3" fontSize={{ base: "20px", xl: "24px" }} mb="8px" fontWeight="500px">
              {current?.userName}
            </Text>
            <StructuredTextParser
              str={render(current?.testimonialText)?.slice(0, 300)}
              region={''}
              lang={''}
              fontFamily="FedraSansStd-book"
              mt="0px"
              color="#000"
              fontSize="16px"
              maxW="100%"
            />
          </Box>

          <Box maxW={{ xl: '950px' }}   >
            <Slider {...slickSettings} ref={sliderRef}>
              {sectionContent[2].testimonials?.map((el, index) => {
                return (
                  <Box
                    key={nanoid(5)}
                    pr={{ base: '10px', md: '15px' }}
                  >
                    <Image
                      alt={el?.photo?.url || ''}
                      w="100%"
                      borderRadius="10px"
                      h="auto"
                      // maxH="125.5px"
                      src={el?.photo?.url}
                      objectFit="cover"
                      cursor="pointer"
                      backgroundRepeat='no-repeat'
                      onClick={() => {
                        setTestimonialIndex(index);
                      }}
                    />

                    <Text
                      as="p"
                      fontSize={{ base: '20px', xl: '15px' }}
                      mt="12px"
                      fontWeight="500" pl='10px'
                      fontFamily="FedraSansStd-medium"
                    >
                      {el?.userName}
                    </Text>
                    <Box
                      maxW="100%"
                      fontSize={{ base: '14px', md: '1rem' }}
                      display={{ base: 'block', xl: 'none' }}
                      mt="1rem"
                      pr='15px'
                      pl='10px'
                      w={{
                        base: 'auto',
                        sm: 'auto',
                        md: 'auto'
                      }}
                      textAlign="left"
                      margin="0"
                      fontFamily="FedraSansStd-book"
                    >
                      <StructuredTextParser
                        str={render(el?.testimonialText)}
                        w={{
                          base: 'auto',
                          sm: 'auto',
                          md: '100%'
                        }}
                        p="0"
                        color="#000"
                      />
                    </Box>
                  </Box>
                );
              })}
            </Slider>
          </Box>
        </Flex>
      </Flex>
      <style jsx>
        {`
          @keyframes glow {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Sharings;
