/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import CommonComponents from '@sw/Common/CommonComponents';
import StyleConfig from '@sw/Common/StyleConfig';
import SwProgramGuide from '@sw/components/Accordions/programGuide';
import Zigzag1Row from '@sw/components/Cards/Zigzag1Row';
import DatesAndRegistrations from '@sw/components/Tables/DatesAndRegistrationTable';
import Contact from '@sw/components/UI/Contact';
import IframeForm from '@sw/components/UI/IframeForm';
import ContentImageBox from '@sw/sections/ContentImageBox';
import ManasarovarText from '@sw/sections/ManasarovarText';
import Offerings from '@sw/sections/Offerings';
import OtherYatras from '@sw/sections/OtherYatras';
import QuotesGallery from '@sw/sections/QuotesGallery';
import Sharings from '@sw/sections/Sharings';
import SubduingSection from '@sw/sections/SubduingSection';
import WhyGoPilgrimage from '@sw/sections/WhyGoPilgrimage';
import YatraInfoSection from '@sw/sections/YatraInfoSection';

/**
 * Renders Render LB Sections
 *
 * @param {object} sectionContent - Data
 * @param {string} region - Region of Page
 * @param {string} lang - Language of Page
 * @returns {ReactElement} Render MSR Sections
 */

const RenderSections = ({
  section,
  region,
  lang,
  pType,
  csrData,
  headerMenuData,
  topMenuData,
  pageData,
}) => {
  const { sectionId } = section;
  if (sectionId === 'contact-us-grid') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <Contact sectionContent={section.sectionContent} section={section} />
      </StyleConfig>
    );
  } else if (sectionId === 'iframe-form') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <IframeForm sectionContent={section.sectionContent} section={section} />
      </StyleConfig>
    );
  } else if (sectionId === 'yatra-info-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <YatraInfoSection
          sectionContent={section.sectionContent}
          section={section}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'other-yatras') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <OtherYatras
          sectionContent={section.sectionContent}
          section={section}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'offerings') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <Offerings sectionContent={section.sectionContent} section={section} />
      </StyleConfig>
    );
  } else if (sectionId === 'why-go-pilgrimage') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <WhyGoPilgrimage
          sectionContent={section.sectionContent}
          section={section}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'mansarovar-text') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <ManasarovarText
          sectionContent={section.sectionContent}
          section={section}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'subduing-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        <SubduingSection
          sectionContent={section.sectionContent}
          section={section}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'sharings') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <Sharings sectionContent={section.sectionContent} section={section} />
      </StyleConfig>
    );
  } else if (sectionId === 'content-image-box') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <ContentImageBox
          sectionContent={section.sectionContent}
          section={section}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'zigzag-1-row') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <Zigzag1Row sectionContent={section.sectionContent} section={section} />
      </StyleConfig>
    );
  } else if (sectionId === 'quotes-and-gallery') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <QuotesGallery
          sectionContent={section.sectionContent}
          section={section}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'program-guide') {
    return (
      <SwProgramGuide
        sectionContent={section.sectionContent}
        section={section}
      />
    );
  } else if (sectionId === 'date-registration') {
    return (
      <DatesAndRegistrations
        sectionContent={section.sectionContent}
        section={section}
      />
    );
  } else {
    return (
      <Box as="div" display="block" placeContent="center">
        {section?.sectionContent && section?.sectionContent?.length
          ? section?.sectionContent?.map((secCon, i) => {
              return (
                <StyleConfig
                  id={section?.sectionId}
                  key={section?.sectionId}
                  style={section?.styleConfig}
                  section={section}
                  // pB={{ base: '0', md: '15px' }}
                >
                  <CommonComponents
                    key={nanoid()}
                    sectionContent={secCon}
                    section={section}
                    region={region}
                    language={lang}
                    pType={pType}
                    csrData={csrData}
                    pageData={pageData}
                    index={i}
                  />
                </StyleConfig>
              );
            })
          : null}
      </Box>
    );
  }
};

export default RenderSections;
