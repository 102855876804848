/* Built In Imports */
/* External Imports */
import { Box, Flex, Image } from '@chakra-ui/react';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders the QuotesGallery component
 *
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} QuotesGallery component
 */

const QuotesGallery = ({ sectionContent }) => {
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 6,
    arrows: false,
    swipeToSlide: true,
    adaptiveHeight: false,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      background="#f3f3f3"
      mb='15px'
    >
      <Box
        mx="auto"
        padding={{ lg: '80px 0', md: '60px 0', base: '30px 0' }}
        maxW={{ base: "100%" }}
        m={{ base: "0", md: "0" }}
      >
        <Box
          marginBottom="40px"
          justifyContent="center"
          alignItems="center"
          maxW={{ base: "100%" }}
          mx='auto'
        >
          <Box
            color="#001490"
            fontSize={{ lg: '20px', md: '20px', base: '14px' }}
            fontFamily="FedraSerifAStdBook"
            fontWeight="normal"
            textAlign="center"
            whiteSpace={{ lg: "pre-line" }}
            lineHeight={{ base: "24px", md: "25px" }}
            p={"0 10px"}
          >
            {sectionContent[0]?.quote}
          </Box>
          <Box
            color="#001490"
            fontSize={{ lg: '20px', md: '20px', base: '20px' }}
            fontFamily="FedraSansStd-medium"
            fontWeight="500"
            paddingTop="10px"
            lineHeight="normal"
            textAlign="center"
          >
            {sectionContent[0]?.author}
          </Box>
        </Box>

        <Box justifyContent="center" p="0 0% 0 0%">
          <Slider {...slickSettings}>
            {sectionContent[1]?.images?.map((image, i) => {
              return (
                <Box key={i}>
                  <Flex
                    // boxShadow="8px 6px 16px 0 rgb(0 0 0 / 60%)"
                    bg="transparent"
                    height={{ lg: '100%', md: '330px', base: '100%' }}
                    width={{ base: '98%' }}
                    _hover={{ boxShadow: 'none' }}
                    borderRadius="25px"
                    justifyContent="center"
                  >
                    <Image
                      src={image.url}
                      alt={image.alt}
                      borderRadius="10px"
                      height={{ lg: '100%', md: '330px', base: '100%' }}
                      width={{ base: '100%' }}
                      objectFit="cover"
                    />
                  </Flex>
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};

export default QuotesGallery;
