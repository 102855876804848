/* External Imports */
import { Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/**
 *
 * @returns
 */
const IframeForm = ({ sectionContent }) => {
  return (
    <>
      <Flex
        justify="center"
        pos="relative"
        top={{ base: '-5vh', lg: '-15.6vh' }}
      >
        <Box
          borderRadius="1rem"
          width={{ base: '100%', lg: '70vw' }}
          boxShadow="0 0 2px 4px rgba(0,0,0,0.1)"
          bg="#f7f7f7"
        >
          <Box
            // py={{ base: '0px', md: '0',lg:"34px" }}
            pt={{ base: '20px', lg: '34px' }}
            fontSize={{ base: '30px', md: '40px', lg: '50px' }}
            fontWeight="500"
            textAlign="center"
            fontFamily="FedraSansStd-A-medium"
          >
            {sectionContent[0].text}
          </Box>
          <Box
            width="100%"
            height={{
              base: '1950px',
              sm: '1800px',
              md: '1650px',
              xl: '1500px',
            }}
            // scrolling="no"
            overflowX="hidden"
            overflowY="auto"
          >
            <Box
              as="iframe"
              width="100%"
              height={{
                base: '1950px',
                sm: '1800px',
                md: '1650px',
                xl: '1500px',
              }}
              // scrolling="no"
              overflowX="hidden"
              overflowY="auto"
              src={sectionContent[1].linkUrl}
            ></Box>
          </Box>          
        </Box>
      </Flex>
    </>
  );
};

export default IframeForm;
