/* External Imports */
import { Box, Center, Heading, Link, useBreakpointValue } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import parser from 'html-react-parser';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

/* Internal Imports */
/* Components */
import BlueBanner from '@sw/components/Banners/BlueBanner';
import StructuredTextParser from '@sw/components/Utility/StructuredTextParser';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const LandingPageTopBanner = ({ sectionContent }) => {
  const textAlignDynamic = parser(render(sectionContent?.location) || '');
  const getValue = textAlignDynamic?.props?.children;

  if (getValue === 'home') {
    return (
      <>
        <Box
          // bgImage={{ base: sectionContent?.mobileImage?.url, md: sectionContent?.desktopImage?.url }}
          // h={{ md: '100vh', base: 'auto' }}
          backgroundSize={{ base: 'cover', md: 'cover' }}
          // backgroundPosition='center'
          backgroundRepeat="no-repeat"
          display="flex"
          alignItems="center"
        >
          <Box display={{ base: 'none', md: 'block' }}>
            <video
              id="background-video"
              autoPlay="autoplay"
              loop="loop"
              muted
              poster={sectionContent?.desktopImage?.url}
            >
              <source src={sectionContent?.desktopVideoLink}
              />
            </video>
          </Box>
          <Box display={{ base: 'block', md: 'none' }}>
            <video
              id="background-video"
              autoPlay="autoplay"
              loop="loop"
              muted
              poster={sectionContent?.mobileImage?.url}
            >
              <source src={sectionContent?.mobileVideoLink}
              />
            </video>
          </Box>

          <style jsx>
            {`
              #background-video {
                width: 100vw;
                max-height: 100vh;
                object-fit: cover;
                position: static;
                left: 0;
                right: 0;
                top: 0px;
                bottom: 0;
                z-index: 10;
              }
            `}
          </style>
        </Box>
      </>
    );
  } else if (getValue === 'why-pilgrimage') {
    return (
      <>
        <Box
          bgImage={{
            base: sectionContent?.mobileImage?.url || sectionContent?.desktopImage?.url,
            md: sectionContent?.desktopImage?.url,
          }}
          h={{ base: '60vh', sm: '60vh', md: '80vh' }}
          backgroundSize={{ base: "cover", sm: "cover" }}
          backgroundRepeat="no-repeat"
          display="flex"
          alignItems="center"
          backgroundPosition={{ base: 'bottom right', md: 'right' }}
          w="full"
        >
          <Box
            maxW="1170px"
            width="full"
            mx="auto"
            display="flex"
            flexWrap="wrap"
            justifyContent={{
              base: 'left',
              md: getValue === 'right-aligned' && 'flex-end',
            }}
          >
            <Box width={{ base: "90%", md: "45%" }} px={{ base: "16px", sm: '30px', md: 0 }} color="#fff">
              <Heading
                width="full"
                as="h1"
                lineHeight={{
                  base: '46px',
                  md: '60px',
                  lg: '60px',
                  xl: '85px',
                }}
                fontFamily="FedraSerifDisplay"
                fontSize={{ base: '45px', md: '65px', lg: '75px', xl: '90px' }}
              >
                {' '}
                {sectionContent?.title}{' '}
              </Heading>
              <Box pt="20px" fontFamily="FedraSerifAStdBook">
                <StructuredTextParser
                  str={render(sectionContent?.titleSubtext)}
                  lineHeight="36px"
                  mt="0"
                  fontSize="25px"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {render(sectionContent?.bottomText) &&
          render(sectionContent?.bottomText) !== '<p></p>' && (
            <BlueBanner text={sectionContent} />
          )}
      </>
    );
  } else if (getValue === 'about') {
    return (
      <>
        <Box
          bgImage={{
            base: sectionContent?.mobileImage?.url || sectionContent?.desktopImage?.url,
            md: sectionContent?.desktopImage?.url,
          }}
          h={{ md: '60vh', base: '60vh' }}
          backgroundSize={{ base: 'cover', md: 'cover' }}
          backgroundRepeat="no-repeat"
          display="flex"
          alignItems="center"
          backgroundPosition="center"
        >
          <Box
            maxW="1170px"
            width="full"
            mx="auto"
            px={{ base: '16px', md: '', lg: '' }}
            pb={{ base: '30px' }}
            pt={{ base: '120px', md: '0px' }}
            display="flex"
            flexWrap="wrap"
            justifyContent={{
              base: 'left',
              md: getValue === 'right-aligned' && 'end',
            }}
          >
            <Box
              width="45%"
              color="#fff"
              display={{ base: 'none', md: 'block' }}
            >
              <Box pt="20px" fontFamily="FedraSerifAStdBook">
                <Center w="100%" mb="10px">
                  <FaQuoteLeft w="auto" fontSize="40px" />
                </Center>
                <StructuredTextParser
                  str={render(sectionContent?.date)}
                  lineHeight="36px"
                  mt="0"
                  fontSize="25px"
                  textAlign="center"
                  color="#fffff"
                />
              </Box>
              <StructuredTextParser
                str={render(sectionContent?.closedMessage)}
                mt="0"
                fontSize="25px"
                textAlign="center"
                color="#fffff"
              />
              <Center w="100%" mt="10px">
                <FaQuoteRight fontSize="40px" />
              </Center>
            </Box>
          </Box>
        </Box>
        {render(sectionContent?.bottomText) &&
          render(sectionContent?.bottomText) !== '<p></p>' && (
            <BlueBanner text={sectionContent} />
          )}
        <Box
          mx="auto"
          maxW="95%"
          color="#000"
          display={{ base: 'block', md: 'none' }}
          textAlign="center"
          my="20px"
        >
          <Center w="100%">
            <FaQuoteLeft w="auto" fontSize="40px" />
          </Center>
          <Box pt="20px" fontFamily="FedraSerifAStdBook">
            <StructuredTextParser
              str={render(sectionContent?.date)}
              lineHeight="36px"
              mt="0"
              textAlign="center"
              fontSize="21px"
              color="#000000"
            />
          </Box>
          <StructuredTextParser
            str={render(sectionContent?.closedMessage)}
            mt="0"
            textAlign="center"
            fontSize="30px"
            color="#000000"
          />
          <Center w="100%">
            <FaQuoteRight fontSize="40px" />
          </Center>
        </Box>
      </>
    );
  } else {
    // right-aligned
    return (
      <>
        <Box
          bgImage={{
            base: sectionContent?.mobileImage?.url || sectionContent?.desktopImage?.url,
            md: sectionContent?.desktopImage?.url,
          }}
          minH={{ base: "350px", md: "60vh" }}
          h={{ base: 'auto', md: '60vh', lg: '60vh' }}
          backgroundSize={{ base: 'cover', md: 'cover' }}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          display="flex"
          alignItems="center"
          position="relative"
        >
          {(sectionContent?.desktopVideoLink || sectionContent?.mobileVideoLink) && (
            <Center
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              zIndex={5}
            >
              <Link
                href={
                  useBreakpointValue({ md: sectionContent.desktopVideoLink, base: sectionContent?.mobileVideoLink })
                } target="_blank">
                <Center

                >
                  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="28" cy="28" r="28" fill="#1A6297" />
                    <path d="M44 28L18 13V43.5L44 28Z" fill="white" />
                  </svg>


                </Center>
              </Link>
            </Center>
          )}
          <Box
            maxW="1170px"
            width="full"
            mx="auto"
            px={{ base: '16px', md: '', lg: '' }}
            pb={{ base: '30px' }}
            pt={{ base: '120px', md: '0px' }}
            display="flex"
            flexWrap="wrap"
            justifyContent={{
              base: 'left',
              md: getValue === 'right-aligned' || getValue === 'right-aligned-black' ? 'flex-end' : 'left',
            }}
          >

            <Box width={{ base: '80%', md: '50%', xl: '45%' }} color={getValue === 'right-aligned-black' ? '#000' : '#fff'}>
              {getValue && (
                <Heading
                  width="full"
                  as="h1"
                  lineHeight={{
                    base: '46px',
                    md: '60px',
                    lg: '60px',
                    xl: '85px',
                  }}
                  fontFamily="FedraSerifDisplay"
                  fontSize={{ base: '45px', md: '65px', lg: '75px', xl: '90px' }}
                >
                  {' '}
                  {sectionContent?.title}{' '}
                </Heading>
              )}
              <Box pt="20px" fontFamily="FedraSerifAStdBook">
                <StructuredTextParser
                  str={render(sectionContent?.titleSubtext)}
                  maxW="none"
                  lineHeight="30px"
                  mt="0"
                  color={getValue === 'right-aligned-black' ? '#000' : "#fff"}
                  fontSize="20px"
                  style={{ fontVariantCaps: 'small-caps', textTransform: 'capitalize' }}
                  p="0px"

                />
              </Box>
            </Box>
          </Box>
        </Box>
        {render(sectionContent?.bottomText) &&
          render(sectionContent?.bottomText) !== '<p></p>' && (
            <BlueBanner text={sectionContent} bType={getValue} />
          )}
      </>
    );
  }
};

export default LandingPageTopBanner;
